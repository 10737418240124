import * as React from 'react';
import { calculatePhotoPrintSizes } from 'Utils';
import { SingleProduct } from 'Models/PrintModel';

const PaperSize = ({ paperSize: { name, id }, paperSize, handler, active }: PaperSizeP) => {
  const styles = calculatePhotoPrintSizes(name);
  const angleCss = { transform: `skew(${styles.angle}rad)` };
  return (
    <div className={`paperSize ${active ? 'paperSize--active' : ''}`}>
      <div className="paperSize__icon--wrapper">
        <div className="paperSize__icon" onClick={() => handler(!active ? paperSize : { id: undefined, variants: [] })} style={styles}>
          <div id="triangle-topleft" style={angleCss} />
        </div>
      </div>
      <div className="paperSize__details">
        <p className="paperSize__details--ratios">{name}</p>
        {/* <span>
          {price.amount} {price.currency}
        </span> */}
      </div>
    </div>
  );
};

export default PaperSize;

type PaperSizeP = {
  paperSize: SingleProduct;
  handler: Function;
  active: boolean;
};
