import * as React from 'react';
import Counter from 'Components/shared/Counter/Counter';
import SVG from 'Components/shared/SVG';
import { SVG_TYPE } from 'Components/shared/enums';

const CheckoutRow = ({ title, value }: CheckoutRowP) => (
  <div className="checkout__row">
    <div className="checkout__title">
      <span>{title}</span>
      <span className="bold">{value}</span>
    </div>
    {/* <div className="checkout__price">{price}</div> */}
  </div>
);

const CheckoutRowBold = ({ title, value, price, onChange }: CheckoutRowBoldP) => {
  return (
    <div className="checkout__row">
      <div className="checkout__title">
        <span>{title}</span>
      </div>
      <Counter quantity={value} onChange={onChange} />
      <div className="checkout__price">{price}</div>
    </div>
  );
};

const CheckoutItem = ({ item: { photo, paperSize, paperType, quantity, price_gross, uuid }, item, editCheckoutItem, deleteCheckoutItem }: CheckoutItemP) => {
  const [newQuantity, setNewQuantity] = React.useState(quantity);
  const handleChange = (value) => {
    setNewQuantity(value);
    editCheckoutItem({ ...item, quantity: value });
  };
  return (
    <div className="checkoutItem">
      <div className="checkoutItem__img">
        <img src={photo.src} alt="Zdjęcie" />
      </div>
      <div className="checkout__summary">
        <CheckoutRow title={'Wymiar:'} value={paperSize.name} />
        <CheckoutRow title={'Papier:'} value={paperType.name} />
        <CheckoutRowBold title={'Odbitek:'} value={newQuantity} price={`${(newQuantity * price_gross).toFixed(2)} ZŁ`} onChange={handleChange} />
        <div className="checkout__close" onClick={() => deleteCheckoutItem(uuid)}>
          <SVG className={'btn__icon'} type={SVG_TYPE.TIMES} />
        </div>
      </div>
    </div>
  );
};

export default CheckoutItem;

type CheckoutItemP = {
  item: any;
  editCheckoutItem: (item) => void;
  deleteCheckoutItem: (uuid) => void;
};

type CheckoutRowP = {
  title: string;
  value: string;
  // price: string;
};
type CheckoutRowBoldP = {
  title: string;
  value: number;
  price: string;
  onChange: (quantity) => void;
};
