import API from './Config';
import { AxiosPromise } from 'axios';

export function sendOrder(data): AxiosPromise<any> {
  return API.post(`orders`, data);
}

export function getPaymentStatus(uuid): AxiosPromise<any> {
  return API.get(`payments/${uuid}`);
}
