import * as React from 'react';
import ROUTES from 'Models/Routes';
import Button from 'Components/shared/Button';
import { Link } from 'react-router-dom';
import basket from '../../../Assets/Img/icon-basket.svg';
import Photos from './components/Photos';
import { SVG_TYPE } from 'Components/shared/enums';
import { Formik, Field } from 'formik';
import * as Yup from 'yup';
import RegularField from '../../shared/form/regularField/regularField';
import { MainType } from './Container';
import { REQUEST_STATUS } from 'Components/shared/enums';
import { history } from 'Store/Reducers/RootReducer';
import dotpay from 'Assets/Img/dotpay-logo.png';
// import przelewy24 from 'Assets/Img/przelewy24.png';
// import visaMastercard from 'Assets/Img/visa_mastercard.png';

class Order extends React.PureComponent<MainType, any> {
  render() {
    const {
      checkoutItems,
      voucher: { value, name },
      voucherStatus,
      checkoutPrices: { amountGross, amountItemsGross, voucherGross },
      actions: { deleteCheckoutItem, getVoucher },
    } = this.props;
    // const checkoutPrice = checkoutItems.reduce((a, { quantity, price_net }) => a + quantity * price_net, 0).toFixed(2);

    const isLoading = () => voucherStatus === REQUEST_STATUS.INIT;
    const validation = () =>
      Yup.object().shape({
        voucher: Yup.string().required('Wprowadź kod'),
      });
    return (
      <div className="order">
        <div className="order__left">
          <Button svgName={SVG_TYPE.CHEVRON_LEFT} label={'Wróć do albumu'} classNames={'btn--gray btn--order'} iconPlacement="left" onClick={() => history.push(ROUTES.PHOTO_PRINT)} />
          <div className="order__basket-mobile">
            <span>
              Ilość plików
              <p>{checkoutItems.length}/500</p>
            </span>
            <div className="order__basket-mobile-icon">
              <img src={basket} alt="basket icon" />
              <p>{checkoutItems.length}</p>
            </div>
          </div>
          <div className="order__left--heading">
            <h1>Twoje Zamówienie</h1>
            <span>
              Ilość plików
              <p>{checkoutItems.length}/500</p>
            </span>
          </div>
          <div className="photo">
            {checkoutItems.map((item) => (
              <Photos item={item} key={item.uuid} deleteCheckoutItem={deleteCheckoutItem} />
            ))}
          </div>
        </div>
        <div className="order__box">
          <div className="order__box--coupon">
            <p className="order__box--label">Dodaj kod rabatowy:</p>
            <Formik initialValues={{ voucher: name ? name : '' }} validationSchema={validation()} onSubmit={(values) => getVoucher(values.voucher)}>
              {({ handleSubmit, errors, values: { voucher } }) => (
                <>
                  <form onSubmit={handleSubmit} className="order__box--form">
                    <Field name="voucher" type="text" component={RegularField} errors={errors} disabled={voucherStatus === REQUEST_STATUS.SUCCESS} />
                    <Button
                      type="submit"
                      svgName={voucherStatus === REQUEST_STATUS.SUCCESS ? SVG_TYPE.SUCCESS : ''}
                      iconPlacement="right"
                      label={voucherStatus === REQUEST_STATUS.SUCCESS ? 'Dodano' : 'Dodaj'}
                      classNames={`btn--gray ${voucherStatus === REQUEST_STATUS.SUCCESS && 'btn--success'}`}
                      isLoading={isLoading()}
                      disabled={voucherStatus === REQUEST_STATUS.SUCCESS}
                    />
                  </form>
                  {value && voucher ? (
                    <p className="order__box--voucher-info fade">
                      Kod rabatowy o wartości <span>- {value}%</span> został dodany
                    </p>
                  ) : (
                    voucher && voucherStatus === REQUEST_STATUS.FAILURE && <p className="order__box--voucher-error fade">Niepoprawny kod rabatowy</p>
                  )}
                </>
              )}
            </Formik>
          </div>
          <div className="order__box--sum">
            <span className="order__box__sum--value">
              {/* Wartość zamówienia <p>{checkoutPrice} zł</p> */}
              Wartość zamówienia <p>{amountItemsGross.toFixed(2)} zł</p>
            </span>
            {value && (
              <span className="fade">
                {/* Rabat <p>-{((value / 100) * checkoutPrice).toFixed(2)} zł</p> */}
                Rabat <p>-{voucherGross.toFixed(2)} zł</p>
              </span>
            )}
            <span className="order__box__sum--transport">
              Dostawa <p>0,00 zł</p>
            </span>
            <span className="order__box__sumary">
              {/* Suma <p>{amountGrossWithVoucher ? amountGrossWithVoucher.toFixed(2) : amountGross.toFixed(2)} zł</p> */}
              Suma <p>{amountGross} zł</p>
            </span>
          </div>
          <Link to={ROUTES.DELIVERY}>
            <Button label="Przejdź do sposobu dostawy" classNames="btn--yellow  btn--delivery" disabled={!checkoutItems.length} />
          </Link>
          <p className="order__box-dotpay">
            <a href="#s" className="order__box--help">
              Płatności realizowane za pomocą
            </a>
            <span>
              <img src={dotpay} alt="dotpay logo" />
              {/* <img src={visaMastercard} alt="visMasterCard logo" /> */}
            </span>
          </p>
          <Link to={ROUTES.CONTACT} className="checkout__sum-contact">
            Potrzebujesz pomocy? skontaktuj się z nami
          </Link>
        </div>
      </div>
    );
  }
}

export default Order;
