import { Checkout } from '../EnumTypes/ActionEnumTypes';

function calculateCheckoutPrice(activeDeliveryMethod = {}) {
  return {
    type: Checkout.CALCULATE_CHECKOUT_PRICE,
    payload: {
      activeDeliveryMethod,
    },
  };
}

export default calculateCheckoutPrice;
