import { ViewManagement } from './EnumTypes/ActionEnumTypes';

function showOuterBanner() {
  return {
    type: ViewManagement.SHOW_OUTER_BANNER,
  };
}

function hideOuterBanner() {
  return {
    type: ViewManagement.HIDE_OUTER_BANNER,
  };
}
function setPrevPath(prevPath: string) {
  return {
    type: ViewManagement.SET_PREV_PATH,
    payload: {
      prevPath,
    },
  };
}

function setMenuExpanded(value: boolean) {
  return {
    type: ViewManagement.SET_MENU_EXPANDED,
    payload: {
      value,
    },
  };
}
function setRetryPayment(isRetryPayment: boolean) {
  return {
    type: ViewManagement.SET_RETRY_PAYMENT,
    payload: {
      isRetryPayment,
    },
  };
}
function manageSelectionMode() {
  return {
    type: ViewManagement.MANAGE_SELECTION_MODE,
  };
}
function showFraming() {
  return {
    type: ViewManagement.SHOW_FRAMING,
  };
}

function hideFraming() {
  return {
    type: ViewManagement.HIDE_FRAMING,
  };
}

export { showOuterBanner, hideOuterBanner, setMenuExpanded, setPrevPath, setRetryPayment, manageSelectionMode, showFraming, hideFraming };
