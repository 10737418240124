import React from 'react';
import Gallery from 'react-photo-gallery';

const SelectedPhotosGallery = ({ selectedPhotosData }: P) => {
  return (
    <div className="selectedPhotosGallery">
      <Gallery
        photos={selectedPhotosData}
        direction={'column'}
        margin={10}
        // columns={window.innerWidth > 1280 ? 4 : undefined}
        columns={4}
        renderImage={({ margin, left, top, photo }: any) => {
          const imgStyle: any = { margin, position: 'absolute', left, top };
          return <img key={photo.uuid} style={imgStyle} {...photo} className={'selection-mode--active'} alt="img" />;
        }}
      />
    </div>
  );
};

type P = {
  selectedPhotosData: any;
};

export default SelectedPhotosGallery;
