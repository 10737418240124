import * as React from 'react';
// import { SingleAttributeValue } from 'Models/PrintModel';

const PaperType = ({ singlePaper: { name, id }, singlePaper, handler, active }: PaperTypeP) => (
  <div className={`paperType ${active ? 'paperType--active' : ''}`}>
    <div className="paperType__icon" onClick={() => handler(!active ? singlePaper : { id: undefined, variants: [] })} />
    <p className="paperType__details">{name}</p>
  </div>
);

export default PaperType;

type PaperTypeP = {
  // singlePaper: {
  //   price: {
  //     amount: number;
  //     currency: string;
  //   };
  //   id: number;
  //   type: string;
  // };
  // singlePaper: SingleAttributeValue;
  singlePaper: any;
  handler: Function;
  active: boolean;
};
