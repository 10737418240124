const ROUTES = {
  HOMEPAGE: '/',
  ABOUTUS: '/o-nas',
  CONTACT: '/kontakt',
  ORDER: '/order',
  PAYMENT_ACCEPTED: '/payment-accepted',
  DELIVERY: '/delivery',
  PHOTO_PRINT: '/photo-print',
  PHOTO_PRINT_CUSTOMIZE: '/photo-print/customize',
  PHOTO_PRINT_CHECKOUT: '/photo-print/checkout',
};

export default ROUTES;
