import { Checkout } from './EnumTypes/ActionEnumTypes';
import { calculateCheckoutPrice } from './Checkout';
import Img13 from 'Assets/Img/img_13.png';

function addItem(checkoutItem) {
  return {
    type: Checkout.ADD_CHECKOUT_ITEM,
    payload: {
      checkoutItem,
    },
  };
}

function addCheckoutItem(checkoutItem) {
  return async (dispatch) => {
    dispatch(addItem(checkoutItem));
    dispatch(calculateCheckoutPrice());
  };
}

function addMultipleCheckoutItems(checkoutItems) {
  return async (dispatch) => {
    dispatch({
      type: Checkout.ADD_MULTIPLE_CHECKOUT_ITEMS,
      payload: {
        checkoutItems,
      },
    });
    dispatch(calculateCheckoutPrice());
  };
}

function editItem(checkoutItem) {
  return {
    type: Checkout.EDIT_CHECKOUT_ITEM,
    payload: {
      checkoutItem,
    },
  };
}

function editCheckoutItem(checkoutItem) {
  return async (dispatch) => {
    dispatch(editItem(checkoutItem));
    dispatch(calculateCheckoutPrice());
  };
}

function deleteItem(uuid) {
  return {
    type: Checkout.DELETE_CHECKOUT_ITEM,
    payload: {
      uuid,
    },
  };
}

function deleteCheckoutItem(checkoutItem) {
  return async (dispatch) => {
    dispatch(deleteItem(checkoutItem));
    dispatch(calculateCheckoutPrice());
  };
}

function getCheckoutItemsSuccess(checkoutItems) {
  return {
    type: Checkout.GET_CHECKOUT_ITEMS,
    payload: {
      checkoutItems,
    },
  };
}

function getCheckoutItems() {
  return async (dispatch) => {
    dispatch(getCheckoutItemsSuccess(checkoutItems));
  };
}

export { getCheckoutItems, addCheckoutItem, editCheckoutItem, deleteCheckoutItem, addMultipleCheckoutItems };

const checkoutItems = [
  {
    count: 1,
    photo: {
      src: Img13,
      width: 289,
      height: 359,
      id: 1,
    },
    printSize: {
      id: 12,
      price: {
        amount: 12.72,
        currency: 'ZŁ',
      },
      type: '12x9',
    },
    printType: {
      id: 3,
      type: 'mat',
      price: {
        amount: 0.79,
        currency: 'ZŁ',
      },
    },
  },
  {
    count: 3,
    photo: {
      src: Img13,
      width: 289,
      height: 359,
      id: 2,
    },
    printSize: {
      id: 12,
      price: {
        amount: 12.72,
        currency: 'ZŁ',
      },
      type: '12x9',
    },
    printType: {
      id: 3,
      type: 'mat',
      price: {
        amount: 0.79,
        currency: 'ZŁ',
      },
    },
  },
  {
    count: 5,
    photo: {
      src: Img13,
      width: 289,
      height: 359,
      id: 3,
    },
    printSize: {
      id: 12,
      price: {
        amount: 12.72,
        currency: 'ZŁ',
      },
      type: '12x9',
    },
    printType: {
      id: 3,
      type: 'mat',
      price: {
        amount: 0.79,
        currency: 'ZŁ',
      },
    },
  },
  {
    count: 2,
    photo: {
      src: Img13,
      width: 289,
      height: 359,
      id: 4,
    },
    printSize: {
      id: 12,
      price: {
        amount: 12.72,
        currency: 'ZŁ',
      },
      type: '12x9',
    },
    printType: {
      id: 3,
      type: 'mat',
      price: {
        amount: 0.79,
        currency: 'ZŁ',
      },
    },
  },
  {
    count: 2,
    photo: {
      src: Img13,
      width: 289,
      height: 359,
      id: 4,
    },
    printSize: {
      id: 12,
      price: {
        amount: 12.72,
        currency: 'ZŁ',
      },
      type: '12x9',
    },
    printType: {
      id: 3,
      type: 'mat',
      price: {
        amount: 0.79,
        currency: 'ZŁ',
      },
    },
  },
  {
    count: 2,
    photo: {
      src: Img13,
      width: 289,
      height: 359,
      id: 4,
    },
    printSize: {
      id: 12,
      price: {
        amount: 12.72,
        currency: 'ZŁ',
      },
      type: '12x9',
    },
    printType: {
      id: 3,
      type: 'mat',
      price: {
        amount: 0.79,
        currency: 'ZŁ',
      },
    },
  },
  {
    count: 2,
    photo: {
      src: Img13,
      width: 289,
      height: 359,
      id: 4,
    },
    printSize: {
      id: 12,
      price: {
        amount: 12.72,
        currency: 'ZŁ',
      },
      type: '12x9',
    },
    printType: {
      id: 3,
      type: 'mat',
      price: {
        amount: 0.79,
        currency: 'ZŁ',
      },
    },
  },
];
