import { connector } from '../../../Modules/DeliveryModule';
import { Delivery } from '../EnumTypes/ActionEnumTypes';

const init = () => ({
  type: Delivery.GET_METHODS_INIT,
});

const success = (deliveryMethods: any) => ({
  type: Delivery.GET_METHODS_SUCCESS,
  payload: {
    deliveryMethods,
  },
});

const failure = () => ({
  type: Delivery.GET_METHODS_FAILURE,
});

const getDeliveryMethods = () => async (dispatch) => {
  dispatch(init());
  try {
    const { data } = await connector.getDeliveryMethods();
    dispatch(success(data));
  } catch (err) {
    await dispatch(failure());
    console.log(err);
  }
};

export default getDeliveryMethods;
