import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Main from './Main';
import { selectors } from 'Modules/PrintModule';
import { selectors as viewManagementSelectors } from 'Modules/ViewManagementModule';
import { actions, selectors as checkoutSelectors } from 'Modules/CheckoutModule';

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      addCheckoutItem: actions.addCheckoutItem,
      addMultipleCheckoutItems: actions.addMultipleCheckoutItems,
      //   setActivePhotoId: actions.setActivePhotoId,
      //   showOuterBanner: viewManagementActions.showOuterBanner,
      //   hideOuterBanner: viewManagementActions.hideOuterBanner,
    },
    dispatch,
  ),
});

const mapStateToProps = (state) => ({
  attributes: selectors.getAttributes(state),
  products: selectors.getProducts(state),
  activePhoto: selectors.getActivePhotoDetails(state),
  isSelectionMode: viewManagementSelectors.getIsSelectionMode(state),
  selectedPhotosData: selectors.getSelectedPhotosData(state),
  isFraming: viewManagementSelectors.getIsFraming(state),
  checkoutItems: checkoutSelectors.getCheckoutItems(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);
