import * as React from 'react';
import Button from 'Components/shared/Button';
import addPictureIcon from 'Assets/Img/add-picture-icon.svg';
import { SVG_TYPE } from 'Components/shared/enums';

const ActionBar = ({
  openCheckout,
  photosAmount,
  dropzoneRef,
  checkoutItemsAmount,
  handleSelectionMode,
  isSelectionMode,
  selectedPhotosCount,
  deleteSelectedPhotos,
  openCustomization,
  addSelectedPhoto,
}: ActionBarP) => (
  <div className="action-bar">
    <div className="action-bar--l">
      <Button label={'Dodaj'} iconName={addPictureIcon} iconPlacement="left" classNames={'btn--gray'} onClick={() => dropzoneRef.open()} />
      <Button label={`Dodano: ${photosAmount}`} classNames={'btn--gray'} />
    </div>
    <div className="action-bar--r">
      {selectedPhotosCount ? (
        <>
          <Button label={`Usuń zaznaczone ${selectedPhotosCount}`} svgName={SVG_TYPE.DELETE} iconPlacement="left" classNames={'btn--gray btn-choose'} onClick={deleteSelectedPhotos} />
          <Button label={`Dostosuj ${selectedPhotosCount}`} svgName={SVG_TYPE.EDIT} iconPlacement="left" classNames={'btn--gray btn-choose'} onClick={openCustomization} />
        </>
      ) : null}
      {isSelectionMode ? (
        <>
          <Button label={'Anuluj wybieranie'} svgName={SVG_TYPE.SELECT} iconPlacement="right" classNames={'btn--gray btn-choose yellow-icon'} onClick={handleSelectionMode} />
          <Button label={'Zaznacz wszystkie'} classNames={'btn--gray btn-choose yellow-icon'} onClick={() => addSelectedPhoto(null, true)} />
        </>
      ) : (
        <Button label={'Wybierz'} svgName={SVG_TYPE.SELECT} iconPlacement="right" classNames={'btn--gray btn-choose'} onClick={handleSelectionMode} />
      )}
      <Button label={`Koszyk: ${checkoutItemsAmount}`} svgName={SVG_TYPE.CART} iconPlacement="right" classNames={'btn--yellow btn-basket'} onClick={openCheckout} disabled={!checkoutItemsAmount} />
    </div>
  </div>
);

export default ActionBar;

type ActionBarP = {
  openCheckout: any;
  photosAmount: number;
  dropzoneRef: any;
  checkoutItemsAmount: number;
  handleSelectionMode: () => void;
  deleteSelectedPhotos: () => void;
  addSelectedPhoto: (uuid, areAllToSelect) => void;
  openCustomization: any;
  isSelectionMode: boolean;
  selectedPhotosCount: any;
};
