import React from 'react';

const Product = ({ product }: P) => {
  return (
    <div className="order__product">
      <div className="order__product--left">
        <div className="order__product--name">
          <p>Nazwa pliku:</p>
          <span>{product.file.original_name}</span>
        </div>
        <div className="order__product--paper">
          <p>Papier:</p>
          <span>{product.name}</span>
        </div>
        <div className="order__product--prints">
          <p>Odbitek:</p>
          <span>{product.quantity}</span>
        </div>
      </div>
      <div className="order__product--right">
        <p>{(product.quantity * product.price_gross).toFixed(2)} zł</p>
      </div>
    </div>
  );
};

type P = {
  product: any;
};

export default Product;
