import * as React from 'react';

type P = {
  onClick: () => void;
  isActive: boolean;
  disabled?: boolean;
};

const Switch = ({ onClick, isActive, disabled = false }: P) => (
  <div className="switch-wrapper">
    <span onClick={!disabled ? onClick : undefined} className={`switch-wrapper__switch ${isActive ? 'switch-wrapper__switch--active' : ''}`}>
      <div className="switch-wrapper__switch_inner" />
    </span>
  </div>
);

export default Switch;
