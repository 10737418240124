import { Delivery } from '../EnumTypes/ActionEnumTypes';
import { calculateCheckoutPrice } from '../Checkout';

function setActiveMethodSuccess(data) {
  return {
    type: Delivery.SET_ACTIVE_METHOD,
    payload: {
      data,
    },
  };
}

function setActiveMethod(data) {
  return async (dispatch) => {
    dispatch(setActiveMethodSuccess(data));
    dispatch(calculateCheckoutPrice(data));
  };
}

export default setActiveMethod;
