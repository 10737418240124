import * as React from 'react';
import bg from 'Assets/Img/customization_bg.png';
import Button from 'Components/shared/Button';
import { CheckoutItem } from './components';
import Routes from 'Models/Routes';
import { Link } from 'react-router-dom';
import ROUTES from 'Models/Routes';
import { SVG_TYPE } from 'Components/shared/enums';

class Checkout extends React.Component<any, any> {
  hideCheckout = () => {
    const { prevPath } = this.props;
    this.props.history.push(prevPath);
    prevPath !== ROUTES.PHOTO_PRINT_CUSTOMIZE && this.props.actions.hideOuterBanner();
  };

  showPanel = () => {
    this.setState({ isCustomizationPanelVisible: true });
    this.props.history.push(Routes.PHOTO_PRINT_CUSTOMIZE);
    this.props.actions.showOuterBanner();
  };

  handleDeleteCheckoutItem = (uuid) => {
    const {
      checkoutItems,
      prevPath,
      actions: { deleteCheckoutItem, hideOuterBanner },
    } = this.props;
    deleteCheckoutItem(uuid);
    if (!checkoutItems.length && prevPath === ROUTES.PHOTO_PRINT_CUSTOMIZE) {
      this.props.history.push(Routes.PHOTO_PRINT_CUSTOMIZE);
    } else if (!checkoutItems.length && prevPath === ROUTES.PHOTO_PRINT) {
      this.props.history.push(Routes.PHOTO_PRINT);
      hideOuterBanner();
    }
  };

  render() {
    const {
      checkoutItems,
      checkoutPrices: { amountGross },
      actions: { editCheckoutItem },
    } = this.props;

    return (
      <div className="checkout">
        <img className="checkout__bg" src={bg} alt="kolorowa grafika" />
        <div className="checkout__inner" style={{ overflow: 'hidden' }}>
          <div style={{ height: '100%' }}>
            <div className="checkout__heading--wrapper">
              <div className="checkout__heading">
                <h1>Twój koszyk</h1>
                <Button classNames={'btn-shopping'} svgName={SVG_TYPE.CHEVRON_LEFT} label={'Schowaj'} iconPlacement={'left'} onClick={this.hideCheckout} />
              </div>
            </div>
            <div className="checkout__items">
              {checkoutItems.map((item) => (
                <CheckoutItem item={item} key={item.uuid} editCheckoutItem={editCheckoutItem} deleteCheckoutItem={this.handleDeleteCheckoutItem} />
              ))}
            </div>
          </div>
        </div>
        <div className="checkout__sum--wrapper">
          <div className="checkout__sum">
            <div className="checkout__price">
              <p>Razem:</p>
              {/* <span className="checkout__price--bold">{checkoutItems.reduce((a, { quantity, price_net }) => a + quantity * price_net, 0).toFixed(2)} zł</span> */}
              <span className="checkout__price--bold">{amountGross.toFixed(2)} zł</span>
            </div>
            <Link to={ROUTES.ORDER}>
              <Button classNames={'btn--yellow'} label={'Przejdź do zamówienia'} iconPlacement={'right'} />
            </Link>
            <Link to={ROUTES.CONTACT} className="checkout__sum-contact">
              Potrzebujesz pomocy? skontaktuj się z nami
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default Checkout;
