import * as React from 'react';
import Logo from 'Assets/Img/logo-big.png';
import BigPicture from 'Assets/Img/about-us-picture.png';

const AboutUs = () => {
  return (
    <div className="about-us">
      <div className="about-us__header">
        <img className="about-us__logo" src={Logo} alt="logo" />
      </div>
      <div className="about-us__wrapper">
        <div className="about-us__section">
          <h1 className="about-us__title"> O nas</h1>
          <div className="about-us__text">
            <p>
              ePhoto to miłośnicy fotografii, którzy wierzą, że analogowy świat zdjęć wciąż jest żywy. Posiadamy bogaty park maszyn oraz dopracowaną do perfekcji logistykę. Wisienką na torcie jest
              prosty w obsłudze system ephoto, który pozwala na szybkie zamówienia odbitek online w najbardziej popularnych formatach oraz na dobrej jakości papierze w bardzo konkurencyjnej cenie.
            </p>
            <p>
              Nasz zespół pozostaje do Twojej dyspozycji. Prócz pasji do fotografii znamy się na obsłudze klienta oraz na technikach druku cyfrowego. Posiadamy bogaty park maszynowy, który pozwala nam
              na szybką i sprawną realizację zdjęć, które dotrą do Ciebie w zaledwie kilka dni.
            </p>
          </div>
        </div>
        <div className="about-us__big-picture">
          <img src={BigPicture} alt="logo" />
        </div>
        <h1 className="about-us__big-title">Drukujemy zdjęcia od ponad 20 lat</h1>
        <div className="about-us__background">
          <div className="about-us__section">
            <h1 className="about-us__title"> Początki</h1>
            <div className="about-us__text">
              <p style={{ maxWidth: '450px' }}>
                Nasza historia sięga zamierzchłych czasów. Ponad 20 lat temu, podczas szybkiego wzrostu fotografii cyfrowej postanowiliśmy iść pod prąd wierząc, że spadek zainteresowania fotografią
                analogową nie będzie początkiem jej końca. Uwierzyliśmy w zdjęcia na ścianie i klaserze. uwierzyliśmy w fotografów uwieczniających chwile swoich klientów, chcących pozostawić im
                trwałą, namacalną pamiątkę. Obecnie realizujemy kilkaset tysięcy odbitek w miesiącu, co jest dowodem na to, że fotografia cyfrowa jeszcze długo nie obędzie się bez trwałego nośnika,
                jakim jest papier zdjęciowy.
              </p>
            </div>
          </div>
          {/* <div className="about-us__section">
            <h1 className="about-us__title"> Historia</h1>
            <div className="about-us__text">
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum in dolor sodales, tempor elit ac, sodales massa. Vestibulum eleifend, libero et suscipit pellentesque, lacus neque
                dapibus erat, id luctus sem risus eget lectus. Integer tristique justo ac dolor efficitur elementum. Nullam pretium at orci vestibulum malesuada. Nam quis justo lorem. Mauris commodo
                est vel ligula porttitor eleifend. Vivamus luctus orci quis turpis porttitor varius. Vivamus pretium odio at tortor commodo, eget euismod lacus semper.
              </p>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum in dolor sodales, tempor elit ac, sodales massa. Vestibulum eleifend, libero et suscipit pellentesque, lacus neque
                dapibus erat, id luctus sem risus eget lectus. Integer tristique justo ac dolor efficitur elementum. Nullam pretium at orci vestibulum malesuada. Nam quis justo lorem. Mauris commodo
                est vel ligula porttitor eleifend. Vivamus luctus orci quis turpis porttitor varius. Vivamus pretium odio at tortor commodo, eget euismod lacus semper.
              </p>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
