import * as React from 'react';
import LandingPage from './Pages/LandingPage';
import AboutUs from './Pages/AboutUs';
import Order from './Pages/Order/Container';
import OrderFinish from './Pages/OrderFinish/Container';
import Delivery from './Pages/Delivery/Container';
import Contact from './Pages/Contact';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { bindActionCreators } from 'redux';
import { store } from '../Store/Store';
import { Route, Switch } from 'react-router-dom';
import { history } from '../Store/Reducers/RootReducer';
import { connect } from 'react-redux';
import Header from './Layout/Header/Container';
import Routes from 'Models/Routes';
import PhotoPrint from 'Components/Pages/PhotoPrint/Container';
import CustomizePhoto from 'Components/Pages/CustomizePhoto/Container';
import Checkout from 'Components/Pages/Checkout/Container';
import { selectors as viewManagementSelectors } from 'Modules/ViewManagementModule';
import { actions as viewManagementActions } from 'Modules/ViewManagementModule';
import { actions as printActions } from 'Modules/PrintModule';
import { actions as deliveryActions } from 'Modules/DeliveryModule';
import * as H from 'history';
import ROUTES from 'Models/Routes';
import SnackbarProvider from 'react-simple-snackbar';
import { Global } from 'Store/Actions/EnumTypes/ActionEnumTypes';

type PropsLocation = {
  location: H.Location;
};

type ConnectedP = {
  isOuterBannerVisible: boolean;
  isFraming: boolean;
  isSelectionMode: boolean;
};

type DispatchedP = {
  actions: {
    hideOuterBannerVisible: () => void;
    setActivePhotoId: (id) => void;
    getAttributeValues: () => void;
    getDeliveryMethods: () => void;
    getProducts: () => void;
    setPrevPath: (prevPath) => void;
    clearStore: () => void;
  };
};

class App extends React.Component<PropsLocation & ConnectedP & DispatchedP, any> {
  public async componentDidMount() {
    await this.props.actions.getAttributeValues();
    await this.props.actions.getProducts();
    await this.props.actions.getDeliveryMethods();
  }

  public componentDidUpdate() {
    const { location } = this.props;
    if (location.pathname !== ROUTES.PHOTO_PRINT_CHECKOUT && location.pathname !== ROUTES.PHOTO_PRINT_CUSTOMIZE) {
      this.props.actions.hideOuterBannerVisible();
    }
  }

  shouldComponentUpdate({ location }) {
    const {
      location: { pathname, search },
      actions: { setPrevPath, clearStore },
    } = this.props;

    if (location.pathname !== pathname) {
      setPrevPath(pathname);
      if (
        (pathname === ROUTES.PAYMENT_ACCEPTED && (search === '?status=OK' || search === '?status=FAIL') && location.pathname !== ROUTES.DELIVERY) ||
        (pathname === ROUTES.DELIVERY && sessionStorage.getItem('order') !== null)
      ) {
        sessionStorage.removeItem('order');
        clearStore();
      }
    }

    return true;
  }

  public render() {
    const {
      isOuterBannerVisible,
      isFraming,
      isSelectionMode,
      location: { pathname },
    } = this.props;

    return (
      <div className={`main-wrapper ${isOuterBannerVisible ? 'main-wrapper--outerBannerVisible' : ''} ${isFraming && window.innerWidth <= 1200 ? 'main-wrapper--100vh' : ''}`}>
        <div className={`home-page page page__inner ${pathname === ROUTES.PHOTO_PRINT_CHECKOUT && isOuterBannerVisible ? 'blurred' : ''}`}>
          <Header location={this.props.location} />
          <SnackbarProvider>
            <Switch>
              <Route exact path={Routes.HOMEPAGE} component={LandingPage} />
              <Route path={Routes.PHOTO_PRINT} component={PhotoPrint} />
              <Route path={Routes.ABOUTUS} component={AboutUs} />
              <Route path={Routes.ORDER} component={Order} />
              <Route path={Routes.CONTACT} component={Contact} />
              <Route path={Routes.PAYMENT_ACCEPTED} component={OrderFinish} />
              <Route path={Routes.DELIVERY} component={Delivery} />
              {/* <Route path={Routes.ORDER_BASKET} component={OrderBasket} /> */}
            </Switch>
          </SnackbarProvider>
        </div>
        {isOuterBannerVisible && (
          <div
            className={`outerBanner ${pathname === ROUTES.PHOTO_PRINT_CHECKOUT && isOuterBannerVisible ? 'shadowed' : ''} ${isSelectionMode && window.innerWidth <= 1200 ? 'outerBanner--mg-80' : ''}`}>
            <Switch>
              <Route exact path={Routes.PHOTO_PRINT_CUSTOMIZE} component={CustomizePhoto} />
              <Route exact path={Routes.PHOTO_PRINT_CHECKOUT} component={Checkout} />
            </Switch>
          </div>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      hideOuterBannerVisible: () => viewManagementActions.hideOuterBanner(),
      getAttributeValues: () => printActions.getAttributeValues(),
      getDeliveryMethods: () => deliveryActions.getDeliveryMethods(),
      getProducts: () => printActions.getProducts(),
      setActivePhotoId: (id) => printActions.setActivePhotoId(id),
      setPrevPath: (prevPath) => viewManagementActions.setPrevPath(prevPath),
      clearStore: () => dispatch({ type: Global.CLEAR_STORE }),
    },
    dispatch,
  ),
});

const mapStateToProps = (state) => ({
  isOuterBannerVisible: viewManagementSelectors.isOuterBannerVisible(state),
  isFraming: viewManagementSelectors.getIsFraming(state),
  isSelectionMode: viewManagementSelectors.getIsSelectionMode(state),
});
const AppMain = connect(mapStateToProps, mapDispatchToProps)(App);

const RootApp = () => (
  <Provider store={store}>
    <ConnectedRouter history={history}>
      <Route path="/" component={AppMain} />
    </ConnectedRouter>
  </Provider>
);
export default RootApp;
