import { createSelector } from 'reselect';

const getcheckoutStore = (state) => state.checkoutStore;
// const getCheckoutItems = createSelector(getcheckoutStore, checkoutStore => get(checkoutStore, 'checkoutItems', []));
const getCheckoutItems = createSelector(getcheckoutStore, ({ checkoutItems = [] }) => checkoutItems);
const getVoucher = createSelector(getcheckoutStore, ({ voucher: { data = { name: '', value: '' } } }) => data);
const getVoucherStatus = createSelector(getcheckoutStore, ({ voucher: { status } }) => status);
const getCheckoutPrices = createSelector(getcheckoutStore, ({ amountGross, amountNet, amountItemsGross, voucherGross }) => ({
  amountGross,
  amountNet,
  amountItemsGross,
  voucherGross,
}));

export { getCheckoutItems, getVoucher, getVoucherStatus, getCheckoutPrices };
