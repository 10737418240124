import * as Types from '../../Models/UserModel';
import * as ActionTypes from '../Actions/EnumTypes/ActionEnumTypes';
// import { UserAction } from '../Actions/Models/UserActionModel';
import { REQUEST_STATUS } from 'Components/shared/enums';

export type State = Types.UserStore;

export const initialState: any = {
  token: null,
  user: null,
  contactForm: {
    data: {},
    status: REQUEST_STATUS.IDLE,
  },
};

export function userReducer(state: any = initialState, action) {
  switch (action.type) {
    case ActionTypes.User.SEND_MESSAGE_INIT: {
      return {
        ...state,
        contactForm: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case ActionTypes.User.SEND_MESSAGE_SUCCESS: {
      return {
        ...state,
        contactForm: {
          // data: action.payload.deliveryMethods,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case ActionTypes.User.SEND_MESSAGE_FAILURE: {
      return {
        ...state,
        contactForm: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    default: {
      return state;
    }
  }
}
