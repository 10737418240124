import { connect } from 'react-redux';
import Main from './Main';
import { bindActionCreators } from 'redux';
import { selectors, actions } from 'Modules/CheckoutModule';
import { selectors as OrderSelectors, actions as OrderActions } from 'Modules/OrderModule';
import { selectors as deliverySelectors, actions as deliveryActions } from 'Modules/DeliveryModule';
import { selectors as ViewManagementSelectors } from 'Modules/ViewManagementModule';
import { getVoucher } from 'Store/Actions/Checkout';
import { RootState } from 'Store/Reducers/RootReducer';
import { Global } from 'Store/Actions/EnumTypes/ActionEnumTypes';
import { REQUEST_STATUS } from 'Components/shared/enums';

type ConnectedP = {
  checkoutItems: Array<any>;
  deliveryMethods: Array<any>;
  voucher: any;
  activeMethod: any;
  checkoutPrices: any;
  orderStatus: REQUEST_STATUS;
  isRetryPayment: boolean;
};

type DispatchedP = {
  actions: {
    deleteCheckoutItem: (uuid) => void;
    getVoucher: (voucher) => void;
    setOrder: (data, userRole, delivery_details) => void;
    setActiveMethod: (data) => void;
    clearStore: () => void;
  };
};

export type MainType = ConnectedP & DispatchedP;

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      deleteCheckoutItem: actions.deleteCheckoutItem,
      getVoucher: getVoucher,
      setOrder: OrderActions.setOrder,
      setActiveMethod: deliveryActions.setActiveMethod,
      clearStore: () => dispatch({ type: Global.CLEAR_STORE }),
    },
    dispatch,
  ),
});

const mapStateToProps = (state: RootState): ConnectedP => ({
  checkoutItems: selectors.getCheckoutItems(state),
  checkoutPrices: selectors.getCheckoutPrices(state),
  voucher: selectors.getVoucher(state),
  deliveryMethods: deliverySelectors.getDeliveryMethods(state),
  activeMethod: deliverySelectors.getActiveMethod(state),
  orderStatus: OrderSelectors.getOrderStatus(state),
  isRetryPayment: ViewManagementSelectors.getIsRetryPayment(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);
