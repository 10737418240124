import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import * as fromUserReducer from './UserReducer';
import * as fromViewManagementReducer from './ViewManagementReducer';
import * as fromPrintsReducer from './PrintsReducer';
import * as fromCheckoutReducer from './CheckoutReducer';
import * as fromDeliveryReducer from './DeliveryReducer';
import * as fromOrderReducer from './OrderReducer';
import { createBrowserHistory } from 'history';

export type RootState = {
  router: any;
  userStore: fromUserReducer.State;
  deliveryMethods: fromDeliveryReducer.State;
  orderStore: fromOrderReducer.State;
};

export const history = createBrowserHistory();

const rootReducer = combineReducers<RootState>({
  router: connectRouter(history),
  userStore: fromUserReducer.userReducer,
  viewManagementStore: fromViewManagementReducer.viewManagementReducer,
  printStore: fromPrintsReducer.printsReducer,
  checkoutStore: fromCheckoutReducer.checkoutReducer,
  deliveryStore: fromDeliveryReducer.deliveryReducer,
  orderStore: fromOrderReducer.orderReducer,
} as any);

const appReducer = (state, action) => {
  if (action.type === 'CLEAR_STOR') {
    state = undefined;
  }
  return rootReducer({ ...state }, action);
};

export default appReducer;
