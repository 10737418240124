import * as React from 'react';

type P = {
  progressPercentage: number;
};

const ProgressBarLoader = ({ progressPercentage }: P) => {
  return (
    <div className="progress">
      <div className="progress__wrapper-bar">
        <div className="progress__bar" style={{ width: progressPercentage + '%' }}></div>
      </div>
    </div>
  );
};

export default ProgressBarLoader;
