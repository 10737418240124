import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Main from './Main';
import { actions as viewManagementActions, selectors as viewManagementSelectors } from 'Modules/ViewManagementModule';
import { selectors, actions } from 'Modules/CheckoutModule';
import { selectors as printSelectors } from 'Modules/PrintModule';

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getCheckoutItems: actions.getCheckoutItems,
      editCheckoutItem: actions.editCheckoutItem,
      deleteCheckoutItem: actions.deleteCheckoutItem,
      hideOuterBanner: viewManagementActions.hideOuterBanner,
    },
    dispatch,
  ),
});

const mapStateToProps = (state) => ({
  checkoutItems: selectors.getCheckoutItems(state),
  checkoutPrices: selectors.getCheckoutPrices(state),
  // checkoutItems: state.checkoutStore.checkoutItems,
  photos: printSelectors.getPhotos(state),
  prevPath: viewManagementSelectors.getPrevPath(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);
