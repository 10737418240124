import * as ActionTypes from '../Actions/EnumTypes/ActionEnumTypes';

export const initialState: any = {
  photos: [],
  activePhotoId: undefined,
  attributes: [],
  products: [],
  selectedPhotos: [],
};

export function printsReducer(state: any = initialState, action) {
  switch (action.type) {
    case ActionTypes.Prints.GET_PHOTOS: {
      // console.log(action.payload.photos && action.payload.dimensions);
      return {
        ...state,
        photos: action.payload.photo
          ? [
              ...state.photos,
              {
                // ...action.payload.photos,
                id: state.photos.length + 1,
                uuid: action.payload.photo.uuid,
                src: URL.createObjectURL(action.payload.photo),
                width: action.payload.photo.width,
                height: action.payload.photo.height,
                name: action.payload.photo.name,
                isLoading: true,
              },
            ]
          : [],
        // photos: action.payload.photos,
      };
    }
    case ActionTypes.Prints.SET_PROGRESS_PERCENTAGE: {
      const { uuid: loadingItemUuid, progress } = action.payload;
      const index = state.photos.findIndex(({ uuid }) => uuid === loadingItemUuid);
      const photos = state.photos;
      // photos[index] = { ...photos[index], loadingpercentage: progress, isLoading: progress !== 100 };
      photos[index] = { ...photos[index], loadingpercentage: progress };
      return {
        ...state,
        photos: [...photos],
      };
    }
    case ActionTypes.Prints.SET_FILE_UUID: {
      const { uuid: loadingItemUuid, file_uuid } = action.payload;
      const index = state.photos.findIndex(({ uuid }) => uuid === loadingItemUuid);
      const photos = state.photos;
      photos[index] = { ...photos[index], file_uuid, isLoading: false };
      return {
        ...state,
        photos: [...photos],
      };
    }
    case ActionTypes.Prints.SET_ACTIVE_PHOTO_ID: {
      return {
        ...state,
        activePhotoId: action.payload.id,
      };
    }
    case ActionTypes.Prints.CHANGE_LOADING_STATUS: {
      return {
        ...state,
        photos: state.photos.map((photo) => (photo.id === action.payload.id ? { ...photo, isLoading: false } : photo)),
      };
    }
    case ActionTypes.Prints.DELETE_ACTIVE_PHOTO: {
      const index = state.photos.findIndex((photo) => photo.id === state.activePhotoId);
      const photos = state.photos;
      photos.splice(index, 1);
      let newActivePhotoId = state.activePhotoId;

      if (photos[index]) {
        newActivePhotoId = photos[index].id;
      } else if (photos[index - 1]) {
        newActivePhotoId = photos[index - 1].id;
      }

      return {
        ...state,
        photos: [...photos],
        activePhotoId: newActivePhotoId,
      };
    }
    case ActionTypes.Prints.GET_ATTRIBUTE_VALUES: {
      return {
        ...state,
        attributes: action.payload.attributes,
      };
    }
    case ActionTypes.Prints.GET_PRODUCTS: {
      return {
        ...state,
        products: action.payload.products,
      };
    }
    case ActionTypes.Prints.SAVE_CROPPED_IMAGE: {
      state.photos.splice(action.payload.image.activePhotoIndex + 1, 0, {
        id: state.photos.length + 1,
        uuid: action.payload.image.uuid,
        src: action.payload.image.src,
        width: action.payload.image.width,
        height: action.payload.image.height,
        name: state.photos[action.payload.image.activePhotoIndex].name,
        isLoading: false,
      });
      return {
        ...state,
        photos: [...state.photos],
        activePhotoId: state.photos.length,
      };
    }
    case ActionTypes.Prints.ADD_SELECTED_PHOTO: {
      const { areAllToSelect, uuid } = action.payload;
      return {
        ...state,
        selectedPhotos: areAllToSelect ? [...state.photos.map(({ uuid }) => uuid)] : [...state.selectedPhotos, uuid],
      };
    }
    case ActionTypes.Prints.UNCHECK_SELECTED_PHOTO: {
      const arr = state.selectedPhotos.filter((uuid) => uuid !== action.payload.uuid);
      return {
        ...state,
        selectedPhotos: [...arr],
      };
    }
    case ActionTypes.Prints.UNCHECK_SELECTED_PHOTOS: {
      return {
        ...state,
        selectedPhotos: [],
      };
    }
    case ActionTypes.Prints.DELETE_SELECTED_PHOTOS: {
      const arr = state.photos.filter(({ uuid }) => !state.selectedPhotos.includes(uuid));
      return {
        ...state,
        photos: [...arr],
        selectedPhotos: [],
      };
    }
    default: {
      return state;
    }
  }
}
