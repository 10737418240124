import * as React from 'react';
import Button from 'Components/shared/Button';
import ROUTES from 'Models/Routes';
import dotpay from 'Assets/Img/dotpay-logo.png';
// import visaMastercard from 'Assets/Img/visa_mastercard.png';
import { Formik } from 'formik';
import * as Yup from 'yup';
// import RegularField from '../../shared/form/regularField/regularField';
import '../../../Assets/Sass/Components/form.scss';
import basket from '../../../Assets/Img/icon-basket.svg';
import { SVG_TYPE } from 'Components/shared/enums';
import { MainType } from './Container';
import { history } from 'Store/Reducers/RootReducer';
import { REQUEST_STATUS } from 'Components/shared/enums';
import Switch from 'Components/shared/switch';
import { companyValidation, customerValidation, FormField } from './components';

enum USER_ROLE {
  CUSTOMER = 'customer',
  COMPANY = 'company',
}

declare global {
  interface Window {
    easyPack: any;
    easyPackAsyncInit: any;
    point: any;
    inpostDelivery: any;
    set: any;
  }
}

type S = {
  inpostDelivery: any;
  userRole: USER_ROLE;
};

class Delivery extends React.PureComponent<MainType, S> {
  constructor(props) {
    super(props);
    this.state = {
      inpostDelivery: [],
      userRole: USER_ROLE.CUSTOMER,
    };
  }

  componentDidMount() {
    const { delivery_method_id, delivery_price_net, delivery_price_gross, delivery_details } = JSON.parse(sessionStorage.getItem('order')) || {};
    if (delivery_method_id) {
      this.props.actions.setActiveMethod({ id: delivery_method_id, price_net: delivery_price_net, price_gross: delivery_price_gross });
    }
    if (delivery_details) {
      this.setState({ inpostDelivery: delivery_details });
    }
  }

  handleClick = () => {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const set = this;
    window.easyPack.modalMap(
      function (point, modal) {
        modal.closeModal();
        set.setState({
          inpostDelivery: [point['name'], ', ', point['address']['line1'], ', ', point['address_details']['province'], ', ', point['address']['line2']],
        });
      },
      { width: 500, height: 600 },
    );
  };

  render() {
    const {
      checkoutItems,
      voucher: { value },
      deliveryMethods,
      activeMethod: { price_gross },
      isRetryPayment,
      orderStatus,
      checkoutPrices: { amountGross, amountItemsGross, voucherGross },
      actions: { setOrder, setActiveMethod, clearStore },
    } = this.props;

    const { inpostDelivery, userRole } = this.state;
    const { CUSTOMER, COMPANY } = USER_ROLE;
    const formValuesToDelete = ['nip', 'name', 'company_zip_code', 'company_address', 'company_city'];
    const isLoading = () => orderStatus === REQUEST_STATUS.INIT;

    window.easyPackAsyncInit = function () {
      window.easyPack.init({
        defaultLocale: 'pl',
        mapType: 'osm',
        searchType: 'osm',
        points: {
          types: ['parcel_locker', 'pop'],
        },
        map: {
          initialTypes: ['parcel_locker', 'pop'],
        },
      });
    };

    const { delivery_method_id, customer: { email = '', first_name = '', last_name = '', address = null, zip_code = null, city = null, phone = '' } = {} } =
      JSON.parse(sessionStorage.getItem('order')) || {};

    const handleBack = async () => {
      if (sessionStorage.getItem('order')) {
        sessionStorage.removeItem('order');
        clearStore();
      }
      history.push(ROUTES.ORDER);
    };

    const handleToggle = () => {
      this.setState((prevState) => ({ userRole: prevState.userRole === CUSTOMER ? COMPANY : CUSTOMER }));
    };

    const checkIfIsAdressRequired = (delivery_method_id) => deliveryMethods.some(({ id, is_address_required }) => id === delivery_method_id && is_address_required);

    return (
      <div className="order">
        <Formik
          initialValues={{
            delivery_method_id: delivery_method_id,
            email: email,
            first_name: first_name,
            last_name: last_name,
            address: address,
            zip_code: zip_code,
            city: city,
            phone: phone,
            name: '',
            nip: '',
            company_address: '',
            company_zip_code: '',
            company_city: '',
          }}
          enableReinitialize
          validationSchema={(props) =>
            Yup.lazy(({ delivery_method_id }) =>
              userRole === CUSTOMER ? customerValidation(checkIfIsAdressRequired(delivery_method_id)) : companyValidation(checkIfIsAdressRequired(delivery_method_id)),
            )
          }
          onSubmit={(values) => {
            const tempValues = { ...values };
            delete tempValues.delivery_method_id;
            if (userRole === CUSTOMER) {
              formValuesToDelete.forEach((key) => delete tempValues[key]);
            }
            tempValues.phone = String(tempValues.phone);

            setOrder(tempValues, userRole === COMPANY, inpostDelivery);
          }}>
          {({ handleSubmit, errors, setFieldValue, values: { delivery_method_id } }) => (
            <>
              <div className="order__left">
                <Button
                  svgName={SVG_TYPE.CHEVRON_LEFT}
                  label={isRetryPayment ? 'Anuluj zamówienie' : 'Wróć do zamówienia'}
                  classNames={'btn--gray btn--order btn--back-order'}
                  iconPlacement="left"
                  onClick={handleBack}
                />
                <div className="order__basket-mobile">
                  <span>
                    Ilość plików
                    <p>{checkoutItems.length}/500</p>
                  </span>
                  <div className="order__basket-mobile-icon">
                    <img src={basket} alt="basket icon" />
                    <p>{checkoutItems.length}</p>
                  </div>
                </div>
                <div className="order__left--heading order__left--heading-delivery">
                  <h1>Twoje dane i sposób dostawy</h1>
                </div>
                <div className="order__left-wrapper">
                  <p className="form__data--heading">Sposób dostawy</p>
                  <form className="form__delivery">
                    <div className="form__delivery--group">
                      {deliveryMethods.map(({ name, price_net, price_gross, id }, idx) => {
                        return (
                          <>
                            <div
                              className="form__delivery__single"
                              key={idx}
                              onClick={() => {
                                setFieldValue('delivery_method_id', id);
                                setActiveMethod({ name, price_net, price_gross, id });
                              }}>
                              <div className="form__delivery__single--l">
                                <label className={`form__field--icon ${id === delivery_method_id ? 'form__field--icon-active' : ''}`}>
                                  <label></label>
                                </label>
                                <p>{name}</p>
                              </div>
                              <div className="form__delivery__single--r">{price_gross.toFixed(2)} ZŁ</div>
                            </div>
                          </>
                        );
                      })}
                    </div>
                    {delivery_method_id ? (
                      <div className="form__delivery--info">
                        {/* Check if inPost24 is picked, then show map integration */}
                        {delivery_method_id === 3 && (
                          <div className="form__delivery--data3">
                            <p className="form__data--heading">Wybierz miejsce odbioru</p>
                            <p className="form__data--choose-place" onClick={() => this.handleClick()}>
                              {inpostDelivery.length ? inpostDelivery : 'Wybierz paczkomat z mapy'}
                            </p>
                            <div id="easypack-map"></div>
                          </div>
                        )}
                        <div className="form__delivery--switch">
                          <div className="form__switch-wrapper">
                            <p> Jestem firmą</p>
                            <Switch onClick={handleToggle} isActive={userRole === COMPANY} />
                          </div>
                        </div>
                        {userRole === CUSTOMER ? null : (
                          <>
                            <div className="form__delivery--data1">
                              <p className="form__data--heading">Dane do faktury</p>
                              <FormField headingLabel={'Nazwa firmy'} infoLabel={'Podaj nazwę firmy'} type={'text'} name={'name'} fieldClassName={'form__data--text-field'} errors={errors} />
                              <FormField headingLabel={'NIP'} infoLabel={'Podaj nip firmy'} type={'number'} name={'nip'} fieldClassName={'form__data--text-field'} errors={errors} />
                              <FormField
                                headingLabel={'Kod pocztowy'}
                                infoLabel={'Podaj kod pocztowy'}
                                type={'text'}
                                name={'company_zip_code'}
                                fieldClassName={'form__data--text-field form__data--tel-number'}
                                errors={errors}
                              />
                              <FormField
                                headingLabel={'Miasto'}
                                infoLabel={'Podaj miasto'}
                                type={'text'}
                                name={'company_city'}
                                fieldClassName={'form__data--text-field form__data--tel-number'}
                                errors={errors}
                              />
                              <FormField
                                headingLabel={'Ulica'}
                                infoLabel={'Podaj ulicę'}
                                type={'text'}
                                name={'company_address'}
                                fieldClassName={'form__data--text-field form__data--tel-number'}
                                errors={errors}
                              />
                            </div>
                          </>
                        )}
                        <>
                          <div className="form__delivery--data1">
                            <p className="form__data--heading">Dane Zamawiającego</p>
                            <FormField headingLabel={'E-mail'} infoLabel={'Podaj adres e-mail'} type={'text'} name={'email'} errors={errors} />
                            <FormField
                              headingLabel={'Numer telefonu'}
                              infoLabel={'Podaj numer telefonu'}
                              type={'number'}
                              name={'phone'}
                              fieldClassName={'form__data--text-field form__data--tel-number'}
                              errors={errors}
                            />
                            <FormField headingLabel={'Imię'} infoLabel={'Podaj swoje imię'} type={'text'} name={'first_name'} fieldClassName={'form__data--text-field'} errors={errors} />
                            <FormField headingLabel={'Nazwisko'} infoLabel={'Podaj swoje nazwisko'} type={'text'} name={'last_name'} fieldClassName={'form__data--text-field'} errors={errors} />
                          </div>
                        </>
                        {checkIfIsAdressRequired(delivery_method_id) ? (
                          <div className="form__delivery--data2">
                            <p className="form__data--heading">Adres dostawy</p>
                            <FormField
                              headingLabel={'Kod pocztowy'}
                              infoLabel={'Podaj kod pocztowy'}
                              type={'text'}
                              name={'zip_code'}
                              fieldClassName={'form__data--text-field form__data--tel-number'}
                              errors={errors}
                            />
                            <FormField
                              headingLabel={'Miasto'}
                              infoLabel={'Podaj miasto'}
                              type={'text'}
                              name={'city'}
                              fieldClassName={'form__data--text-field form__data--tel-number'}
                              errors={errors}
                            />
                            <FormField
                              headingLabel={'Ulica'}
                              infoLabel={'Podaj ulicę'}
                              type={'text'}
                              name={'address'}
                              fieldClassName={'form__data--text-field form__data--tel-number'}
                              errors={errors}
                            />
                          </div>
                        ) : null}
                      </div>
                    ) : null}
                  </form>
                </div>
              </div>
              <div className="order__box order__box-delivery">
                <div className="order__box--sum">
                  <span className="order__box__sum--value">
                    Wartość zamówienia <p>{amountItemsGross.toFixed(2)} zł</p>
                  </span>
                  {value && (
                    <span className="fade">
                      Rabat <p>-{voucherGross.toFixed(2)} zł</p>
                    </span>
                  )}
                  <span className="order__box__sum--transport">
                    Dostawa <p>{price_gross ? price_gross.toFixed(2) : '0,00'} zł</p>
                  </span>
                  <span className="order__box__sumary">
                    {/* Suma <p>{amountGrossWithVoucher ? amountGrossWithVoucher.toFixed(2) : amountGross.toFixed(2)} zł</p> */}
                    Suma <p>{amountGross} zł</p>
                  </span>
                </div>
                <Button
                  type="submit"
                  onClick={() => handleSubmit()}
                  iconPlacement="left"
                  label={orderStatus === REQUEST_STATUS.SUCCESS ? 'Trwa przekierowanie...' : isRetryPayment ? 'Ponów płatność' : 'Zamów i przejdź do płatności'}
                  classNames="btn--yellow btn--finish "
                  isLoading={isLoading()}
                  disabled={(delivery_method_id === 3 && !inpostDelivery.length) || !delivery_method_id}
                />
                <p className={`order__box-delivery ${delivery_method_id ? 'hidden' : ''}`}>Wybierz sposób dostawy</p>
                <p className={`order__box-delivery-finish ${delivery_method_id ? 'active' : ''}`}>Kliknięcie w przycisk Zamów i przejście do płatności wiąże się z obowiązkiem zapłaty</p>
                <p className="order__box-dotpay">
                  <a href="#s" className="order__box--help">
                    Płatności realizowane za pomocą
                  </a>
                  <span>
                    <img src={dotpay} alt="dotpay logo" />
                    {/* <img src={visaMastercard} alt="visaMasterCard logo" /> */}
                  </span>
                </p>
              </div>
            </>
          )}
        </Formik>
      </div>
    );
  }
}

export default Delivery;
