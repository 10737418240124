import * as Types from '../../Models/DeliveryModel';
import * as ActionTypes from '../Actions/EnumTypes/ActionEnumTypes';
import { REQUEST_STATUS } from 'Components/shared/enums';

export type State = Types.DeliveryStore;

export const initialState: State = {
  deliveryMethods: {
    data: [],
    status: REQUEST_STATUS.IDLE,
  },
  activeMethod: {
    id: undefined,
    name: undefined,
    price_net: undefined,
    price_gross: undefined,
  },
};

export function deliveryReducer(state: State = initialState, action) {
  switch (action.type) {
    case ActionTypes.Delivery.GET_METHODS_INIT: {
      return {
        ...state,
        deliveryMethods: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case ActionTypes.Delivery.GET_METHODS_SUCCESS: {
      return {
        ...state,
        deliveryMethods: {
          data: action.payload.deliveryMethods,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case ActionTypes.Delivery.GET_METHODS_FAILURE: {
      return {
        ...state,
        deliveryMethods: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case ActionTypes.Delivery.SET_ACTIVE_METHOD: {
      return {
        ...state,
        activeMethod: action.payload.data,
      };
    }
    case ActionTypes.Global.CLEAR_STORE: {
      return {
        ...state,
        activeMethod: {
          id: undefined,
          name: undefined,
          price_net: undefined,
        },
      };
    }
    default: {
      return state;
    }
  }
}
