import * as React from 'react';
import ROUTES from 'Models/Routes';
import Button from 'Components/shared/Button';
import { Link } from 'react-router-dom';
import dotpay from 'Assets/Img/dotpay-logo.png';
// import visaMastercard from 'Assets/Img/visa_mastercard.png';
import { SVG_TYPE, PAYMENT_STATUS, ORDER_STATUS } from 'Components/shared/enums';
import { MainType } from './Container';
import * as H from 'history';
import { history } from 'Store/Reducers/RootReducer';
import { Product } from './components';

type PropsLocation = {
  location: H.Location;
};

type S = {
  order: any;
  isRedirecting: boolean;
  counter: number;
  interval: any;
};

class OrderFinish extends React.PureComponent<MainType & PropsLocation, S> {
  constructor(props) {
    super(props);
    this.state = {
      order: JSON.parse(sessionStorage.getItem('order')),
      isRedirecting: false,
      counter: 10,
      interval: null,
    };
  }

  componentDidMount() {
    const {
      location: { search },
      actions: { getPaymentStatus, setRetryPayment },
    } = this.props;
    const { interval } = this.state;
    const { payment: { uuid = '' } = {} } = this.state.order || {};
    getPaymentStatus(uuid);
    if (search.split('=')[0] === '?status' && search.split('=')[1] === ORDER_STATUS.FAIL) {
      this.setState({ order: null, isRedirecting: true });
      setRetryPayment(true);
      const counter = setInterval(() => {
        if (this.state.counter === 1) {
          clearInterval(counter);
          clearInterval(interval);
          history.push(ROUTES.DELIVERY);
        }
        this.setState(({ counter: prevCounter }) => {
          return {
            counter: prevCounter - 1,
          };
        });
      }, 1000);
    }
    this.setState({
      interval: setInterval(() => {
        const {
          paymentStatus: { operation_status },
        } = this.props;
        operation_status === (PAYMENT_STATUS.COMPLETED || PAYMENT_STATUS.REJECTED) ? clearInterval(interval) : getPaymentStatus(uuid);
      }, 5000),
    });
    // const interval =
    // setInterval(() => {
    //   const {
    //     paymentStatus: { operation_status },
    //   } = this.props;
    //   operation_status === (PAYMENT_STATUS.COMPLETED || PAYMENT_STATUS.REJECTED) ? clearInterval(interval) : getPaymentStatus(uuid);
    // }, 5000);
  }

  handleBack = () => {
    if (sessionStorage.getItem('order')) {
      sessionStorage.removeItem('order');
      this.props.actions.clearStore();
    }
  };
  componentWillUnmount() {
    clearInterval(this.state.interval);
  }

  render() {
    const {
      deliveryMethods,
      paymentStatus: { operation_status },
    } = this.props;
    const { order, isRedirecting, counter } = this.state;
    const {
      uid,
      amountGross,
      amountItemsGross,
      voucherGross,
      delivery_price_gross,
      delivery_method_id,
      customer: { first_name = '', last_name = '', address = '', city = '', email = '', phone = '', zip_code = '', companyData = {} } = {},
    } = order || {};
    const pickedDeliveryMethod = deliveryMethods.find(({ id }) => id === delivery_method_id) || {};

    return (
      <div className="order order__finish">
        <div className="order__left">
          <Link to={ROUTES.PHOTO_PRINT}>
            <Button svgName={SVG_TYPE.CHEVRON_LEFT} label={'Wróć do strony głównej'} classNames={'btn--gray'} iconPlacement="left" onClick={this.handleBack} />
          </Link>
          {order ? (
            <>
              <div className="order__left--heading order__left--heading-delivery">
                <h1>Zamówienie zostało złożone</h1>
                <p className="order__left--confirm-payment">
                  Potwierdzenie zamówienia <span className="order__left--bold">{uid}</span> otrzymasz na podanego maila. Poinformujemy o statusie przesyłki w kolejnych wiadomościach
                </p>
              </div>
              <div className="order__left-box-payment">
                <p>
                  Wartość zamówienia <span>{amountItemsGross.toFixed(2)} zł</span>
                </p>
                <p>
                  Rabat <span>-{voucherGross.toFixed(2)} zł</span>
                </p>
                <p>
                  Dostawa<span>{delivery_price_gross.toFixed(2)} zł</span>
                </p>
                <p>
                  Suma<strong>{amountGross.toFixed(2)} zł</strong>
                </p>
                <p>
                  Status płatności
                  {operation_status && (
                    <span
                      className={`order__payment-status order__payment-status--${
                        operation_status === PAYMENT_STATUS.COMPLETED ? 'completed' : operation_status === PAYMENT_STATUS.REJECTED ? 'rejected' : 'processing'
                      }`}>
                      <img src={dotpay} alt="dotpay logo" />
                      {/* <img src={visaMastercard} alt="visaMasterCard logo" /> */}
                      {operation_status === PAYMENT_STATUS.COMPLETED ? 'Opłacono' : operation_status === PAYMENT_STATUS.REJECTED ? 'Odrzucono' : 'W trakcie przetwarzania'}
                    </span>
                  )}
                </p>
              </div>
              <p className="form__data--heading">Dane dostawy</p>
              <div className="order-delivery">
                <p>Typ dostawy: {pickedDeliveryMethod.name}</p>
                {address ? (
                  <>
                    <span></span>
                    <p>
                      {zip_code}, {city}
                    </p>
                    <p>{address}</p>
                  </>
                ) : null}
              </div>
              <div className="order-delivery order-delivery-customer">
                <p>Dane kupującego</p>
                {companyData?.name && (
                  <p>
                    {companyData.name} (NIP: {companyData.nip})
                  </p>
                )}
                <p>
                  {first_name} {last_name}
                </p>
                {companyData?.name && (
                  <p className="order__finish--padding">
                    {companyData.zip_code}, {companyData.city} <br /> {companyData.address}
                  </p>
                )}
                <p>+48 {phone}</p>
                <p>{email}</p>
              </div>
            </>
          ) : isRedirecting ? (
            <div className="order__left--heading order__left--heading-delivery">
              <h1>Wystąpił błąd podczas operacji płatności</h1>
              <p className="order__left--confirm-payment">
                Prawdopodobnie odrzuciłeś płatność dotpay lub bank odrzucił Twoją realizację. W celu ponownego zrealizowania zamówienia następi przekierowanie do widoku składania zamówienia.
                <span className="order__left--counter">Przekierowanie nastąpi za {counter} sekund...</span>
              </p>
            </div>
          ) : (
            <div className="order__left--heading order__left--heading-delivery">
              <h1>Brak danych zamówienia</h1>
              <p className="order__left--confirm-payment">Prawdopodobnie odwiedzasz tą stronę w inny sposób niż będąc przekierowanym po dokonanej płatności</p>
            </div>
          )}
        </div>
        {order ? (
          <div className="order__right">
            <p className="form__data--heading">Zamówienie</p>
            <div className="order__products-list">
              {order.items.map((product) => {
                return <Product product={product} key={product.uuid} />;
              })}
            </div>
          </div>
        ) : null}
      </div>
    );
  }
}

export default OrderFinish;
