import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import * as React from 'react';
import Slider from 'react-slick';

const settings = {
  arrows: false,
  dots: false,
};
const mainSliderSettings = {
  ...settings,
  draggable: false,
  swipe: true,
  swipeToSlide: true,
  touchMove: true,
  accessibility: false,
  arrows: false,
  slidesToShow: 1,
};

class SliderGallery extends React.Component<any, any> {
  slider1 = null;
  slider2 = null;
  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null,
    };
  }

  componentDidMount() {
    this.setState({
      nav1: this.slider1,
      nav2: this.slider2,
    });
  }

  shouldComponentUpdate({ isFraming, activePhoto }) {
    if (activePhoto.id === this.props.activePhoto.id) {
      const index = this.props.photos.findIndex((photo) => photo.id === this.props.activePhoto.id);
      this.slider1.slickGoTo(index, true);
    }
    return true;
  }

  calculateImgWrapperSize = (photoSizes) => {
    const base = 95;
    const { height, width } = photoSizes;
    const proportion = height / base;
    return {
      height: base,
      width: width / proportion,
    };
  };
  afterChange = (current) => {
    if (this.props.photos[current].id !== this.props.activePhoto.id) {
      this.props.setActivePhotoId(this.props.photos[current].id);
    }
  };

  beforeChange = (current) => {
    this.slider1 && this.slider1.slickGoTo(current, true);
  };
  render() {
    const { photos, initialSlide, activePhoto: { id = '' } = {} } = this.props;
    // this.slider1 && this.slider1.slickGoTo(initialSlide, false);
    // this.slider1 && !isFraming && this.slider1.slickGoTo(id, true);
    return (
      <div className="sliderGallery">
        <div className="sliderGallery--single">
          {/* <Slider {...{ ...mainSliderSettings, infinite: photos.length > 3 }} asNavFor={this.state.nav2} ref={(slider) => (this.slider1 = slider)} beforeChange={this.slider1 && this.slider1.slickGoTo(initialSlide, true)}> */}
          <Slider {...{ ...mainSliderSettings, infinite: photos.length > 3 }} asNavFor={this.state.nav2} ref={(slider) => (this.slider1 = slider)} initialSlide={initialSlide}>
            {photos.map((photo) => {
              // photo.id === photos[initialSlide].id && this.beforeChange(initialSlide);
              return (
                <div className="sliderGallery--single__item" key={photo.id}>
                  <div className="sliderGallery--single__item_inner">
                    <img src={photo.src} alt="" key={photo.src} />
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
        <div className="sliderGallery--nav">
          <Slider
            {...{ ...settings, slidesToShow: photos.length > 5 ? '5' : photos.length, infinite: true }}
            asNavFor={this.state.nav1}
            ref={(slider) => (this.slider2 = slider)}
            beforeChange={this.beforeChange}
            variableWidth
            swipeToSlide
            focusOnSelect
            initialSlide={initialSlide}
            centerMode
            afterChange={this.afterChange}>
            {photos.map((photo) => (
              <div className="sliderGallery--nav__item" key={photo.id}>
                <div className={`sliderGallery--nav__item_inner ${id === photo.id ? 'sliderGallery--active-item' : ''}`}>
                  <img src={photo.src} alt="" key={photo.src} style={this.calculateImgWrapperSize(photo)} />
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    );
  }
}

export default SliderGallery;
