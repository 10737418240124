import { createSelector } from 'reselect';
import { get } from 'lodash';

const getPhotoPrintsStore = (state) => state.printStore;
const getPhotos = createSelector(getPhotoPrintsStore, (photoPrintStore) => get(photoPrintStore, 'photos', []));
const getActivePhotoId = createSelector(getPhotoPrintsStore, (photoPrintStore) => get(photoPrintStore, 'activePhotoId'));
const getActivePhotoDetails = createSelector(getPhotos, getActivePhotoId, (photos, id) => photos.find((photo) => photo.id === id));
const getActivePhotoDetailsIndex = createSelector(getPhotos, getActivePhotoId, (photos, id) => {
  const index = photos.findIndex((photo) => photo.id === id);
  if (index > -1) {
    return index;
  }
  // return 0;
  return undefined;
});
const getAttributes = createSelector(getPhotoPrintsStore, ({ attributes }) => (attributes.length ? attributes[0].values : []));
const getProducts = createSelector(getPhotoPrintsStore, (photoPrintStore) => get(photoPrintStore, 'products', []));
const getSelectedPhotos = createSelector(getPhotoPrintsStore, (photoPrintStore) => get(photoPrintStore, 'selectedPhotos'));
const getSelectedPhotosData = createSelector(getPhotos, getSelectedPhotos, (photos, selectedPhotos) => {
  const photosToDisplay = photos.filter(({ uuid }) => selectedPhotos.includes(uuid));
  return photosToDisplay;
});

export { getPhotos, getActivePhotoId, getActivePhotoDetails, getActivePhotoDetailsIndex, getAttributes, getProducts, getSelectedPhotos, getSelectedPhotosData };
