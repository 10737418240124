enum SVG_TYPE {
  CHEVRON_LEFT = 'CHEVRON_LEFT',
  FRAME = 'FRAME',
  SELECT = 'SELECT',
  CART = 'CART',
  TIMES = 'TIMES',
  SUCCESS = 'SUCCESS',
  DELETE = 'DELETE',
  EDIT = 'EDIT',
  PHONE = 'PHONE',
  PDF = 'PDF',
  DOWNLOAD = 'DOWNLOAD',
}

export default SVG_TYPE;
