import React from 'react';
import { Field } from 'formik';
import RegularField from 'Components/shared/form/regularField/regularField';

const formField = ({ type, name, fieldClassName = '', errors, headingLabel, infoLabel }: P) => {
  return (
    <>
      <p className="form__data--headingSmall">{headingLabel}</p>
      <Field type={type} name={name} className={fieldClassName} component={RegularField} errors={errors} />
      <p className="form__data--small-info">{infoLabel} </p>
    </>
  );
};

type P = {
  type: any;
  name: any;
  fieldClassName?: string;
  errors: any;
  headingLabel: string;
  infoLabel: string;
};

export default formField;
