import * as React from 'react';
import SVG from 'Components/shared/SVG';
import { SVG_TYPE } from 'Components/shared/enums';

const Photos = ({ item: { photo, paperSize, paperType, quantity, price_gross, uuid }, deleteCheckoutItem }: PhotosP) => (
  <div className="photo__single">
    <div className="photo__single--left">
      <img src={photo.src} alt=""></img>
      <div className="photo__single--left-prints">{quantity}</div>
    </div>
    <div className="photo__single--right">
      <div className="photo__single--right--info">
        <div className="photo__single--name">
          <p>Nazwa pliku:</p>
          <span>{photo.name}</span>
        </div>
        <div className="photo__single--size">
          <p>Wymiar:</p>
          <span>{paperSize.name} cm</span>
        </div>
        <div className="photo__single--paper">
          <p>Papier:</p>
          <span>{paperType.name}</span>
        </div>
        <div className="photo__single--prints">
          <p>Odbitek:</p>
          <span>{quantity}</span>
        </div>
      </div>
      <div className="photo__single--right--price">
        <p></p>
        <p></p>
        <p>{(quantity * price_gross).toFixed(2)} zł</p>
      </div>
    </div>
    <div className="checkout__close" onClick={() => deleteCheckoutItem(uuid)}>
      <SVG className={'btn__icon'} type={SVG_TYPE.TIMES} />
    </div>
  </div>
);

export default Photos;

type PhotosP = {
  item: any;
  deleteCheckoutItem: (uuid) => void;
};
