enum PAYMENT_STATUS {
  NEW = 'new',
  PROCESSING = 'processing',
  COMPLETED = 'completed',
  REJECTED = 'rejected',
  PROCESSING_REALIZATION_WAITING = 'processing_realization_waiting',
  PROCESSING_REALIZATION = 'processing_realization',
}

export default PAYMENT_STATUS;
