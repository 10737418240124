import * as React from 'react';
import Button from 'Components/shared/Button';
// import { last } from 'lodash';
import deleteIcon from 'Assets/Img/delete-icon.svg';
// import { SVG_TYPE } from 'Components/shared/enums';

// const getImagename = (src) => last(src.split('/'));

const CustomizationPanelFooter = ({ activePhotoName = '', rotateLeft, rotateRight, deleteActivePhoto }: CustomizationPanelFooterP) => (
  <div className="customizationPanelFooter">
    <div className="customizationPanelFooter--l">
      <div className="customizationPanelFooter__actions">
        <Button iconName={deleteIcon} classNames={'btn--red'} onClick={() => deleteActivePhoto()} />
        {/* <span className="image-name">{getImagename(activePhotoSrc)}</span> */}
        <span className="image-name">{activePhotoName}</span>
      </div>
    </div>
    <div className="customizationPanelFooter--r">
      <div className="customizationPanelFooter__actions">
        {/* <Button iconClassName={'fa-shopping-cart'} classNames={'btn--clean'} label={'+ dodaj filtry'} iconPlacement="right" /> */}
        {/* <Button svgName={SVG_TYPE.FRAME} classNames={'btn--clean'} label={'+ dodaj ramkę'} iconPlacement="right" /> */}
        {/* <Button iconClassName={'fa-undo'} classNames={'btn--clean'} />
        <Button iconClassName={'fa-undo'} classNames={'btn--clean'} /> */}
      </div>
    </div>
  </div>
);

export default CustomizationPanelFooter;

type CustomizationPanelFooterP = {
  // activePhotoSrc: string;
  activePhotoName: string;
  rotateLeft?: () => void;
  rotateRight?: () => void;
  deleteActivePhoto: () => void;
};
