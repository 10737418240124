import * as Yup from 'yup';

export const customerValidation = (isAddressRequired = true) =>
  isAddressRequired
    ? Yup.object().shape({
        email: Yup.string().email('Niepoprawny email').required('Pole wymagane'),
        first_name: Yup.string().required('Pole wymagane'),
        last_name: Yup.string().required('Pole wymagane'),
        address: Yup.string().required('Pole wymagane').nullable(),
        zip_code: Yup.string()
          .required('Pole wymagane')
          .nullable()
          .test('zip-code', 'Niepoprawny format (xx-xxx)', (val) => val && !!/^[0-9]{2}-[0-9]{3}$/i.test(val)),
        city: Yup.string().required('Pole wymagane').nullable(),
        phone: Yup.string().required('Pole wymagane').min(9, 'Wymagane 9 cyfr').max(9, 'Wymagane 9 cyfr'),
      })
    : Yup.object().shape({
        email: Yup.string().email('Niepoprawny email').required('Pole wymagane'),
        first_name: Yup.string().required('Pole wymagane'),
        last_name: Yup.string().required('Pole wymagane'),
        phone: Yup.string().required('Pole wymagane').min(9, 'Wymagane 9 cyfr').max(9, 'Wymagane 9 cyfr'),
      });

export const companyValidation = (isAddressRequired = true) =>
  isAddressRequired
    ? Yup.object().shape({
        email: Yup.string().email('Niepoprawny email').required('Pole wymagane'),
        name: Yup.string().required('Pole wymagane'),
        first_name: Yup.string().required('Pole wymagane'),
        last_name: Yup.string().required('Pole wymagane'),
        nip: Yup.string().required('Pole wymagane').min(10, 'Wymagane 10 cyfr').max(10, 'Wymagane 10 cyfr'),
        address: Yup.string().required('Pole wymagane').nullable(),
        zip_code: Yup.string()
          .required('Pole wymagane')
          .nullable()
          .test('zip-code', 'Niepoprawny format (xx-xxx)', (val) => val && !!/^[0-9]{2}-[0-9]{3}$/i.test(val)),
        city: Yup.string().required('Pole wymagane').nullable(),
        phone: Yup.string().required('Pole wymagane').min(9, 'Wymagane 9 cyfr').max(9, 'Wymagane 9 cyfr'),
        company_address: Yup.string().required('Pole wymagane'),
        company_zip_code: Yup.string()
          .required('Pole wymagane')
          .test('zip-code', 'Niepoprawny format (xx-xxx)', (val) => val && !!/^[0-9]{2}-[0-9]{3}$/i.test(val)),
        company_city: Yup.string().required('Pole wymagane'),
      })
    : Yup.object().shape({
        email: Yup.string().email('Niepoprawny email').required('Pole wymagane'),
        name: Yup.string().required('Pole wymagane'),
        first_name: Yup.string().required('Pole wymagane'),
        last_name: Yup.string().required('Pole wymagane'),
        nip: Yup.string().required('Pole wymagane').min(10, 'Wymagane 10 cyfr').max(10, 'Wymagane 10 cyfr'),
        phone: Yup.string().required('Pole wymagane').min(9, 'Wymagane 9 cyfr').max(9, 'Wymagane 9 cyfr'),
        company_address: Yup.string().required('Pole wymagane'),
        company_zip_code: Yup.string()
          .required('Pole wymagane')
          .test('zip-code', 'Niepoprawny format (xx-xxx)', (val) => val && !!/^[0-9]{2}-[0-9]{3}$/i.test(val)),
        company_city: Yup.string().required('Pole wymagane'),
      });
