import * as React from 'react';
import SVG from 'Components/shared/SVG';

type ButtonP = {
  classNames?: string;
  iconClassName?: string;
  iconName?: any;
  iconPlacement?: 'left' | 'right';
  label?: string;
  iconAlt?: string;
  onClick?: () => void;
  type?: 'button' | 'submit' | 'reset';
  svgName?: any;
  disabled?: boolean;
  isLoading?: boolean;
  title?: string;
};
const Button = ({ classNames, iconPlacement, iconClassName, iconName, title, label, disabled = false, onClick, type, svgName, isLoading }: ButtonP) => {
  const icon = () =>
    iconClassName ? (
      <span className="btn__icon">
        <i className={`fas ${iconClassName}`}></i>
      </span>
    ) : iconName ? (
      // <span className="btn__icon">
      <img className="btn__icon" src={iconName} alt="Icon" />
    ) : (
      <SVG className={'btn__icon'} type={svgName} />
    );
  const buttonInner = () =>
    iconClassName || iconName || svgName ? (
      iconPlacement === 'left' ? (
        <>
          {icon()} <p>{label}</p>
        </>
      ) : (
        <>
          <p>{label}</p> {icon()}
        </>
      )
    ) : (
      label
    );

  return (
    <div className="btn--wrapper">
      <button
        onClick={onClick}
        type={type}
        title={title}
        disabled={isLoading || disabled}
        className={`btn ${!label ? 'btn__no-label' : ''} ${classNames} ${isLoading ? 'is-loading' : ''} ${
          (iconClassName || iconName || svgName) && iconPlacement ? `btn--icon btn__icon-placement--${iconPlacement}` : ''
        } ${disabled ? 'btn--disabled' : ''} `}>
        {isLoading && (
          <div className="btn--loader">
            <div className="btn--loader__inner" />
          </div>
        )}
        <span className={`content ${isLoading ? 'content--hide' : ''}`}>{buttonInner()}</span>
      </button>
    </div>
  );
};

export default Button;
