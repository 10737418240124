import * as Types from '../../Models/UserModel';
import * as ActionTypes from '../Actions/EnumTypes/ActionEnumTypes';

export type State = Types.UserStore;

export const initialState: any = {
  outerBannerVisible: false,
  prevPath: null,
  isMenuExpanded: false,
  isRetryPayment: false,
  isSelectionMode: false,
  isFraming: false,
};

export function viewManagementReducer(state: any = initialState, action) {
  switch (action.type) {
    case ActionTypes.ViewManagement.HIDE_OUTER_BANNER: {
      return {
        ...state,
        outerBannerVisible: false,
      };
    }
    case ActionTypes.ViewManagement.SHOW_OUTER_BANNER: {
      return {
        ...state,
        outerBannerVisible: true,
      };
    }
    case ActionTypes.ViewManagement.SET_PREV_PATH: {
      return {
        ...state,
        prevPath: action.payload.prevPath,
      };
    }
    case ActionTypes.ViewManagement.SET_MENU_EXPANDED: {
      return {
        ...state,
        isMenuExpanded: action.payload.value,
      };
    }
    case ActionTypes.ViewManagement.SET_RETRY_PAYMENT: {
      return {
        ...state,
        isRetryPayment: action.payload.isRetryPayment,
      };
    }
    case ActionTypes.Global.CLEAR_STORE: {
      return {
        ...state,
        outerBannerVisible: false,
        prevPath: null,
        isMenuExpanded: false,
        isRetryPayment: false,
      };
    }
    case ActionTypes.ViewManagement.MANAGE_SELECTION_MODE: {
      return {
        ...state,
        isSelectionMode: !state.isSelectionMode,
      };
    }
    case ActionTypes.ViewManagement.HIDE_FRAMING: {
      return {
        ...state,
        isFraming: false,
      };
    }
    case ActionTypes.ViewManagement.SHOW_FRAMING: {
      return {
        ...state,
        isFraming: true,
      };
    }
    default: {
      return state;
    }
  }
}
