import * as React from 'react';
import { SVG_TYPE } from 'Components/shared/enums';

type P = {
  type: SVG_TYPE;
  className?: string;
};
const SVG = ({ type, className }: P) => {
  switch (type) {
    case SVG_TYPE.CHEVRON_LEFT: {
      return (
        <svg className={`svg svg--chevron-left ${className}`} xmlns="http://www.w3.org/2000/svg" width="5.581" height="9.748" viewBox="0 0 5.581 9.748">
          <defs></defs>
          <path className="a" d="M153.834,88.333,149.667,92.5l4.167,4.167" transform="translate(-148.96 -87.626)" />
        </svg>
      );
    }
    case SVG_TYPE.FRAME: {
      return (
        <svg className={`svg svg--frame ${className}`} xmlns="http://www.w3.org/2000/svg" width="15.578" height="14.021" viewBox="0 0 15.578 14.021">
          <path
            className="a"
            d="M17.021,3.116H4.558A1.562,1.562,0,0,0,3,4.674v10.9a1.562,1.562,0,0,0,1.558,1.558H17.021a1.562,1.562,0,0,0,1.558-1.558V4.674A1.562,1.562,0,0,0,17.021,3.116Zm0,12.463H4.558V4.674H17.021ZM15.463,6.231H6.116v7.789h9.347"
            transform="translate(-3 -3.116)"
          />
        </svg>
      );
    }
    case SVG_TYPE.SELECT: {
      return (
        <svg className={`svg svg--select ${className}`} xmlns="http://www.w3.org/2000/svg" width="17.441" height="13.953" viewBox="0 0 17.441 13.953">
          <g className="a" transform="translate(-3.843 -7.218)">
            <path
              className="b"
              d="M20.122,10.125H7.913A1.166,1.166,0,0,0,6.75,11.288v8.721a1.166,1.166,0,0,0,1.163,1.163H20.122a1.166,1.166,0,0,0,1.163-1.163V11.288A1.166,1.166,0,0,0,20.122,10.125Z"
            />
            <path
              className="c"
              d="M 8.749994277954102 12.12499618530273 L 8.749994277954102 19.17125701904297 L 19.2845458984375 19.17125701904297 L 19.2845458984375 12.12499618530273 L 8.749994277954102 12.12499618530273 M 7.912765502929688 10.12499618530273 L 20.12178421020508 10.12499618530273 C 20.76130485534668 10.12499618530273 21.2845458984375 10.64824485778809 21.2845458984375 11.28776550292969 L 21.2845458984375 20.00848579406738 C 21.2845458984375 20.64800643920898 20.76130485534668 21.17125701904297 20.12178421020508 21.17125701904297 L 7.912765502929688 21.17125701904297 C 7.273244857788086 21.17125701904297 6.749994277954102 20.64800643920898 6.749994277954102 20.00848579406738 L 6.749994277954102 11.28776550292969 C 6.749994277954102 10.64824485778809 7.273244857788086 10.12499618530273 7.912765502929688 10.12499618530273 Z"
            />
          </g>
          <g className="a" transform="translate(-1.125 -4.5)">
            <path
              className="b"
              d="M4.177,6.389H16.241V5.663A1.166,1.166,0,0,0,15.078,4.5H2.288A1.166,1.166,0,0,0,1.125,5.663v9.3a1.166,1.166,0,0,0,1.163,1.163h.727V7.552A1.166,1.166,0,0,1,4.177,6.389Z"
            />
            <path
              className="c"
              d="M 2.287766456604004 4.499996185302734 L 15.07816600799561 4.499996185302734 C 15.71768569946289 4.499996185302734 16.24093627929688 5.023245811462402 16.24093627929688 5.662766456604004 L 16.24093627929688 6.389486312866211 L 4.17725658416748 6.389486312866211 C 3.537735939025879 6.389486312866211 3.014495849609375 6.912735939025879 3.014495849609375 7.552255630493164 L 3.014495849609375 16.12763595581055 L 2.287766456604004 16.12763595581055 C 1.648245811462402 16.12763595581055 1.124996185302734 15.60438537597656 1.124996185302734 14.96486663818359 L 1.124996185302734 5.662766456604004 C 1.124996185302734 5.023245811462402 1.648245811462402 4.499996185302734 2.287766456604004 4.499996185302734 Z"
            />
          </g>
        </svg>
      );
    }
    case SVG_TYPE.CART: {
      return (
        <svg className={`svg svg--cart ${className}`} xmlns="http://www.w3.org/2000/svg" width="24.558" height="23.578" viewBox="0 0 24.558 23.578">
          <path className="a" d="M13.96,30.98a.98.98,0,1,1-.98-.98A.98.98,0,0,1,13.96,30.98Z" transform="translate(-3.641 -9.882)" />
          <path className="a" d="M30.46,30.98a.98.98,0,1,1-.98-.98A.98.98,0,0,1,30.46,30.98Z" transform="translate(-9.362 -9.882)" />
          <path className="a" d="M1.5,1.5H5.42L8.046,14.621a1.96,1.96,0,0,0,1.96,1.578H19.53a1.96,1.96,0,0,0,1.96-1.578L23.058,6.4H6.4" transform="translate(0 0)" />
        </svg>
      );
    }
    case SVG_TYPE.TIMES: {
      return (
        <svg className={`svg svg--times ${className}`} xmlns="http://www.w3.org/2000/svg" width="17.353" height="17.353" viewBox="0 0 17.353 17.353">
          <g className="a" transform="translate(0 8.677) rotate(-45)">
            <path d="M11.507,5.371H6.9V.764a.764.764,0,1,0-1.528,0V5.371H.764a.764.764,0,0,0,0,1.528H5.371v4.607a.764.764,0,1,0,1.528,0V6.9h4.608a.764.764,0,1,0,0-1.528Z" />
          </g>
        </svg>
      );
    }
    case SVG_TYPE.SUCCESS: {
      return (
        <svg className={`svg svg--success ${className}`} xmlns="http://www.w3.org/2000/svg" width="9" height="6.711" viewBox="0 0 9 6.711">
          <g transform="translate(-338.667 -142.455)">
            <g transform="translate(336 138)">
              <path
                className="a"
                d="M3.057,11.157.132,8.232a.45.45,0,0,1,0-.636l.636-.636a.45.45,0,0,1,.636,0l1.97,1.97L7.6,4.709a.45.45,0,0,1,.636,0l.636.636a.45.45,0,0,1,0,.636L3.693,11.157A.45.45,0,0,1,3.057,11.157Z"
                transform="translate(2.667 -0.122)"
              />
            </g>
          </g>
        </svg>
      );
    }
    case SVG_TYPE.DELETE: {
      return (
        <svg className={`svg svg--delete ${className}`} xmlns="http://www.w3.org/2000/svg" width="15" height="12" viewBox="0 0 15 12">
          <g transform="translate(-275.264 -386.721)">
            <path
              className="a"
              d="M12.75,15h3v1.5h-3Zm0-6H18v1.5H12.75Zm0,3h4.5v1.5h-4.5Zm-9,4.5A1.5,1.5,0,0,0,5.25,18h4.5a1.5,1.5,0,0,0,1.5-1.5V9H3.75ZM12,6.75H9.75L9,6H6l-.75.75H3v1.5h9Z"
              transform="translate(272.264 380.721)"
            />
          </g>
        </svg>
      );
    }
    case SVG_TYPE.EDIT: {
      return (
        <svg className={`svg svg--edit ${className}`} xmlns="http://www.w3.org/2000/svg" width="16.87" height="14.993" viewBox="0 0 16.87 14.993">
          <path
            d="M11.792,2.441l2.642,2.642a.286.286,0,0,1,0,.4l-6.4,6.4-2.718.3a.57.57,0,0,1-.63-.63l.3-2.718,6.4-6.4A.286.286,0,0,1,11.792,2.441Zm4.745-.671L15.107.341a1.146,1.146,0,0,0-1.617,0L12.454,1.378a.286.286,0,0,0,0,.4L15.1,4.424a.286.286,0,0,0,.4,0l1.037-1.037a1.146,1.146,0,0,0,0-1.617Zm-5.29,8.374v2.982H1.874V3.753H8.605a.36.36,0,0,0,.249-.1l1.172-1.172a.351.351,0,0,0-.249-.6H1.406A1.406,1.406,0,0,0,0,3.284v10.31A1.406,1.406,0,0,0,1.406,15h10.31a1.406,1.406,0,0,0,1.406-1.406V8.972a.352.352,0,0,0-.6-.249L11.349,9.895A.36.36,0,0,0,11.247,10.144Z"
            transform="translate(0 -0.007)"
          />
        </svg>
      );
    }
    case SVG_TYPE.PHONE: {
      return (
        <svg className={`svg svg--phone ${className}`} xmlns="http://www.w3.org/2000/svg" width="34.491" height="34.551" viewBox="0 0 34.491 34.551">
          <path
            className="a"
            d="M22.575,7.5A7.5,7.5,0,0,1,28.5,13.425M22.575,1.5A13.5,13.5,0,0,1,34.5,13.41M33,25.38v4.5a3,3,0,0,1-3.27,3,29.685,29.685,0,0,1-12.945-4.6,29.25,29.25,0,0,1-9-9A29.685,29.685,0,0,1,3.18,6.27,3,3,0,0,1,6.165,3h4.5a3,3,0,0,1,3,2.58A19.26,19.26,0,0,0,14.715,9.8a3,3,0,0,1-.675,3.165l-1.905,1.9a24,24,0,0,0,9,9l1.9-1.905a3,3,0,0,1,3.165-.675,19.26,19.26,0,0,0,4.215,1.05A3,3,0,0,1,33,25.38Z"
            transform="translate(-1.667 0.156)"
          />
        </svg>
      );
    }
    case SVG_TYPE.PDF: {
      return (
        <svg className={`svg svg--pdf ${className}`} xmlns="http://www.w3.org/2000/svg" width="26.442" height="30.849" viewBox="0 0 26.442 30.849">
          <path
            d="M27.842,8.745a4.006,4.006,0,0,1,.826,1.308,3.973,3.973,0,0,1,.344,1.515V31.4a1.646,1.646,0,0,1-1.653,1.653H4.223A1.646,1.646,0,0,1,2.571,31.4V3.856A1.646,1.646,0,0,1,4.223,2.2H19.648a3.974,3.974,0,0,1,1.515.344,4.006,4.006,0,0,1,1.308.826ZM20.2,4.545v6.473h6.473a1.881,1.881,0,0,0-.379-.706L20.9,4.923a1.88,1.88,0,0,0-.706-.379Zm6.61,26.3V13.221H19.648a1.646,1.646,0,0,1-1.653-1.653V4.407H4.774V30.849H26.809ZM17.961,20.64a13.1,13.1,0,0,0,1.446.964,17.082,17.082,0,0,1,2.014-.121q2.531,0,3.047.844a.832.832,0,0,1,.034.9.05.05,0,0,1-.017.034l-.034.034v.017q-.1.654-1.222.654a7.1,7.1,0,0,1-1.98-.344,12.552,12.552,0,0,1-2.238-.912,31,31,0,0,0-6.748,1.429q-2.634,4.51-4.166,4.51a1,1,0,0,1-.482-.121L7.2,28.318q-.017-.017-.1-.086a.711.711,0,0,1-.1-.62,3.738,3.738,0,0,1,.964-1.575,8.319,8.319,0,0,1,2.272-1.661.251.251,0,0,1,.4.1.1.1,0,0,1,.034.069q.9-1.463,1.842-3.391a26.274,26.274,0,0,0,1.79-4.51,13.916,13.916,0,0,1-.525-2.746,6.693,6.693,0,0,1,.112-2.195q.189-.689.723-.689h.379a.726.726,0,0,1,.6.258,1.375,1.375,0,0,1,.155,1.171.373.373,0,0,1-.069.138.448.448,0,0,1,.017.138v.516a22.542,22.542,0,0,1-.241,3.305,8.709,8.709,0,0,0,2.513,4.1ZM8.045,27.715A7.6,7.6,0,0,0,10.4,25,9.813,9.813,0,0,0,8.9,26.442,5.722,5.722,0,0,0,8.045,27.715ZM14.9,11.878a5.119,5.119,0,0,0-.034,2.272q.017-.121.121-.757,0-.052.121-.74a.387.387,0,0,1,.069-.138.05.05,0,0,1-.017-.034.035.035,0,0,0-.009-.026.035.035,0,0,1-.009-.026.991.991,0,0,0-.224-.62.05.05,0,0,1-.017.034v.034ZM12.762,23.257a25.25,25.25,0,0,1,4.889-1.394,2.6,2.6,0,0,1-.224-.164,3.083,3.083,0,0,1-.275-.232,9.119,9.119,0,0,1-2.186-3.03,23,23,0,0,1-1.429,3.391q-.516.964-.775,1.429Zm11.121-.275a4.12,4.12,0,0,0-2.41-.413,6.511,6.511,0,0,0,2.135.482,1.67,1.67,0,0,0,.31-.017q0-.017-.034-.052Z"
            transform="translate(-2.571 -2.204)"
          />
        </svg>
      );
    }
    case SVG_TYPE.DOWNLOAD: {
      return (
        <svg className={`svg svg--download ${className}`} xmlns="http://www.w3.org/2000/svg" width="13.333" height="16" viewBox="0 0 13.333 16">
          <path className="a" d="M20.083,10.146H16.275V4.5H10.558v5.646H6.75l6.667,6.587ZM6.75,18.616V20.5H20.083V18.616Z" transform="translate(-6.75 -4.5)" />
        </svg>
      );
    }
  }
};

export default SVG;
