import * as React from 'react';
import { PaperSize, PaperType, Footer } from './components';
import bg from 'Assets/Img/customization_bg.png';
import { getDeviceType } from 'Utils';
import Slider from 'react-slick';

const settings = {
  arrows: false,
  dots: false,
  slidesToScroll: 1,
  slidesToShow: 3,
  centerMode: true,
  focusOnSelect: true,
};

const CustomizePhoto = ({ attributes, products, isFraming, actions: { addCheckoutItem, addMultipleCheckoutItems }, activePhoto, isSelectionMode, selectedPhotosData, checkoutItems }: any) => {
  const [currentPaperType, setPaperType] = React.useState({ id: undefined });
  const [currentPaperSize, setPaperSize] = React.useState({ id: undefined, variants: [] });
  const [currentPrintPrice, setPrintPrice] = React.useState(0);
  const [currentVariant, setVariant] = React.useState(undefined);

  React.useEffect(() => {
    setPaperSize(currentPaperSize);
    setPaperType(currentPaperType);
    if (currentPaperType.id && currentPaperSize.id) {
      const price = currentPaperSize.variants.find(({ attribute_value_id }) => attribute_value_id === currentPaperType.id);
      setVariant(price ? price : undefined);
      setPrintPrice(price ? price.price_gross : 0);
    }
  }, [currentPaperSize, currentPaperType]);

  const { id, variants } = currentPaperSize;
  const { id: paperTypeId } = currentPaperType;

  const handleSizeClick = (singlePaper) => {
    const { id } = singlePaper;
    setPaperSize(singlePaper);
    // !id && setPaperType({ id: undefined });
    !id && setPrintPrice(0);
  };

  const handleTypeClick = (singlePaperType) => {
    const { id } = singlePaperType;
    setPaperType(singlePaperType);
    !id && setPrintPrice(0);
  };

  return (
    <div className="customizePhoto">
      {isFraming && window.innerWidth <= 1200 ? null : (
        <>
          <img src={bg} alt="" />
          <div className="customizePhoto__inner">
            <div className="customizePhoto__heading">
              <h1 className="yellow">Dostosuj odbitkę</h1>
              <p>po dodaniu do koszyka możesz ponownie dodać to samo zdjęcie w innych wymiarach i na innym papierze</p>
            </div>
            <div className="customizePhoto__offer customizePhoto__offer--paperSize">
              <p>Wybierz wymiar odbitki:</p>
              <div className="customizePhoto__offer--paperSize__wrapper">
                {getDeviceType() === 'DESKTOP' ? (
                  products.map((paperSize) => <PaperSize paperSize={paperSize} handler={handleSizeClick} key={paperSize.id} active={id === paperSize.id} />)
                ) : (
                  <Slider {...settings}>
                    {products.map((paperSize) => (
                      <PaperSize paperSize={paperSize} handler={setPaperSize} key={paperSize.id} active={id === paperSize.id} />
                    ))}
                  </Slider>
                )}
              </div>
            </div>
            {variants.length ? (
              <div className="customizePhoto__offer customizePhoto__offer--paperType">
                <p>Wybierz rodzaj papieru:</p>
                <div className="customizePhoto__offer--paperType__wrapper">
                  {variants.map(({ attribute_value_id }) => {
                    const singlePaper = attributes.find(({ id }) => id === attribute_value_id);
                    return <PaperType singlePaper={singlePaper} handler={handleTypeClick} key={singlePaper.id} active={paperTypeId === singlePaper.id} />;
                  })}
                </div>
              </div>
            ) : null}
            <Footer
              printPrice={currentPrintPrice}
              addCheckoutItem={addCheckoutItem}
              currentVariant={currentVariant}
              activePhoto={activePhoto}
              paperType={currentPaperType}
              paperSize={currentPaperSize}
              isSelectionMode={isSelectionMode}
              selectedPhotosData={selectedPhotosData}
              addMultipleCheckoutItems={addMultipleCheckoutItems}
              checkoutItemsLength={checkoutItems.length}
            />
          </div>
        </>
      )}
    </div>
  );
};

// type ConnectedP = {
//   attributes: Array<SingleAttribute>;
//   products: Array<SingleProduct>;
// };

export default CustomizePhoto;
