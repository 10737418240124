import React from 'react';
import { NavLink } from 'react-router-dom';

const Navigation = ({ hideMenu }: P) => {
  return (
    <>
      <nav>
        <NavLink onClick={hideMenu} to="/o-nas" activeClassName="header__link--active" className="header__link">
          O nas
        </NavLink>
        <NavLink onClick={hideMenu} to="/photo-print" activeClassName="header__link--active" className="header__link">
          Odbitki zdjęć
        </NavLink>
        <NavLink onClick={hideMenu} to="/kontakt" activeClassName="header__link--active" className="header__link">
          Kontakt
        </NavLink>
      </nav>
    </>
  );
};
type P = {
  hideMenu?: () => void;
};
export default Navigation;
