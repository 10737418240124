import * as React from 'react';
import Select, { components } from 'react-select';
import { FieldProps } from 'formik';
import IndicatorsIcon from 'Assets/Img//indicators-icon.svg';

type Props = {
  label?: string;
  options?: any;
  type: string;
  icon?: string;
  disabled: boolean;
  placeholder?: string;
  wrapperClassName?: string;
  rows?: number;
  field: any;
  errors: any;
  form: any;
  isMulti?: boolean;
  isPreacher?: boolean;
};

type S = {
  selectValue: any;
  isSelected: boolean;
  showPlaceholderDate: boolean;
};

type OptionType = {
  label: string;
  value: string;
};
let multiArray = [];

class RegularField extends React.PureComponent<Props & FieldProps, S> {
  constructor(props: Props & FieldProps) {
    super(props);
    this.state = {
      selectValue: null,
      isSelected: false,
      showPlaceholderDate: true,
    };
  }
  public render() {
    const { field, type, placeholder, wrapperClassName, disabled = false, options, errors, form, label, icon } = this.props;
    let formClasses: string = 'form__field';
    formClasses += type ? ` form__field--${type}` : '';
    formClasses += wrapperClassName ? ` form__field--${wrapperClassName} ` : '';
    formClasses += options ? ` form__field--${field.value} ` : '';
    formClasses += disabled ? ' form__field--disabled' : '';
    formClasses += form.touched[field.name] && errors[field.name] ? ' form__field--error' : '';

    // if (field.value && this.props.options && this.props.options.find(option => option.value === field.value)) {
    //   console.log(field.value);
    //   this.setState({ isSelected: true, selectValue: this.props.options.find(option => option.value === field.value) });
    // }

    const onFocus = () => {
      this.setState({ isSelected: true });
    };
    const onBlur = () => {
      this.setState({ isSelected: false });
    };

    const handleChange = (value) => {
      if (value && this.props.isMulti) {
        multiArray = [];
        value.map((val: any) => multiArray.push(val.value));
        this.props.form.setFieldValue(field.name, multiArray);
        this.setState({ selectValue: multiArray.length ? multiArray : '' });
      } else if (value && this.props.isPreacher) {
        this.props.form.setFieldValue(field.name, [value.value]);
        this.setState({ selectValue: value });
      } else {
        this.props.form.setFieldValue(field.name, value ? value.value : '');
        this.setState({ selectValue: value });
      }
    };
    const onChange = (e) => {
      console.log(e.target.files[0]);
      this.props.form.setFieldValue(field.name, e.currentTarget.files[0]);
    };

    const DropdownIndicator = (props) => {
      return (
        <components.DropdownIndicator {...props}>
          <img src={IndicatorsIcon} alt="" />
        </components.DropdownIndicator>
      );
    };

    return (
      <div className={formClasses}>
        {type && type === 'select' ? (
          <>
            {/* <label className="select-label" htmlFor={field.name}> */}
            {label && (
              <label className="field-label" htmlFor={field.name}>
                {label} {form.touched[field.name] && <span className="form__field--label-error">{errors[field.name] && `* ${errors[field.name]}`}</span>}
              </label>
            )}
            {this.props.isPreacher ? (
              <Select
                value={
                  field.value.length > 0
                    ? this.props.options && this.props.options.find((option) => field.value.find((preacher) => option.value === (preacher && preacher.uuid)))
                    : field.value.length === 0 && Array.isArray(field.value)
                    ? this.props.options && this.props.options.find((option) => option.value === null)
                    : ''
                }
                onChange={handleChange}
                onFocus={onFocus}
                isMulti={this.props.isMulti ? true : false}
                noOptionsMessage={() => 'Brak wyników'}
                onBlur={onBlur}
                options={options}
                isClearable={true}
                className="react-select"
                classNamePrefix={`react-select react-select${this.props.isMulti ? '--is-multi' : ''}`}
                placeholder={placeholder}
              />
            ) : (
              <Select
                components={{ DropdownIndicator }}
                value={field.value || field.value == null || field.value === 0 ? this.props.options && this.props.options.find((option) => option.value === field.value) : ''}
                onChange={handleChange}
                onFocus={onFocus}
                isMulti={this.props.isMulti ? true : false}
                noOptionsMessage={() => 'Brak wyników'}
                onBlur={onBlur}
                options={options}
                isClearable={true}
                className="react-select"
                classNamePrefix="react-select"
                placeholder={placeholder}
              />
            )}

            {/* {icon && <i className={`${icon} ${field.value === 1 ? 'verify-success' : field.value === 0 ? 'verify-pending' : ''}`} />} */}
            {/* {errors && form.touched && <div className="form__field--label-error">{form.touched[field.name] && <span>{errors[field.name]}</span>}</div>} */}
          </>
        ) : type && type === 'file' ? (
          <>
            <label className="field-label" htmlFor={field.name}>
              {label}
            </label>
            <input type={type} id={field.name} name={field.name} onChange={onChange} placeholder={placeholder} />
            {/* <i className={icon} /> */}
            {errors && form.touched && <div className="form__field--label-error">{form.touched[field.name] && <span>{errors[field.name]}</span>}</div>}
          </>
        ) : type && type === 'date' ? (
          <>
            <input
              {...field}
              type={(this.state.showPlaceholderDate || form.values.isUnknown) && !field.value ? 'text' : 'date'}
              disabled={form.values.isUnknown}
              onFocus={() => this.setState({ showPlaceholderDate: false })}
              onBlur={() => !form.values.isUnknown && this.setState({ showPlaceholderDate: true })}
              name={field.name}
              placeholder={placeholder}
              onChange={field.onChange}
              checked={field.value}
            />
            {/* {icon && <i className={icon} />} */}
            {errors && form.touched && <div className="form__field--label-error">{form.touched[field.name] && <span>{errors[field.name]}</span>}</div>}
          </>
        ) : type && type === 'textarea' ? (
          <>
            {/* <label className="field-label" htmlFor={field.name}>
              {label} {form.touched[field.name] && <span className="form__field--label-error">{errors[field.name] && `* ${errors[field.name]}`}</span>}
            </label> */}
            <textarea
              {...field}
              type={type}
              disabled={form.values.isUnknown}
              onFocus={() => this.setState({ showPlaceholderDate: false })}
              onBlur={() => !form.values.isUnknown && this.setState({ showPlaceholderDate: true })}
              name={field.name}
              placeholder={placeholder}
              onChange={field.onChange}
              checked={field.value}
            />
            {/* {icon && <i className={icon} />} */}
            {errors && form.touched && <div className="form__field--label-error">{form.touched[field.name] && <span>{errors[field.name]}</span>}</div>}
          </>
        ) : (
          // : type && type === 'checkbox' ? (
          //   <>
          //     <input id={field.name} {...field} type={type} disabled={disabled} onFocus={() => this.setState({ showPlaceholderDate: false })} onBlur={() => !form.values.isUnknown && this.setState({ showPlaceholderDate: true })} name={field.name} placeholder={placeholder} onChange={field.onChange} checked={field.value} />
          //     <label className="form__field--icon" htmlFor={field.name}>
          //       <label></label>
          //     </label>
          //     <label className="form__field--label" htmlFor={field.name}>
          //       {label} {form.touched[field.name] && <span className="form__field--label-error">{errors[field.name] && `* ${errors[field.name]}`}</span>}
          //     </label>
          //     {/* {icon && <i className={icon} />} */}
          //     {/* {errors && form.touched && <div className="form__field--label-error">{form.touched[field.name] && <span>{errors[field.name]}</span>}</div>} */}
          //   </>
          // )
          <>
            {label && (
              <label className="field-label" htmlFor={field.name}>
                {/* {label} {errors && form.touched && <div >{form.touched[field.name] && <span className="form__field--label-error">*{errors[field.name]}</span>}</div>} */}
                {label} {form.touched[field.name] && <span className="form__field--label-error">{errors[field.name] && `* ${errors[field.name]}`}</span>}
              </label>
            )}
            <input
              autoComplete={field.name === 'password' ? 'on' : 'new-password'}
              type={type}
              name={field.name}
              onChange={field.onChange}
              value={field.value}
              placeholder={placeholder}
              disabled={disabled}
            />
            {/* {console.log(field)} */}
            {/* {console.log('errors', errors)} */}
            {!label && errors && form.touched && <div className="form__field--label-error">{form.touched[field.name] && <span>{errors[field.name]}</span>}</div>}
            {icon && <img className="icon" src={icon} alt="" />}
          </>
        )}
      </div>
    );
  }
}

export default RegularField;
