import * as ActionTypes from '../Actions/EnumTypes/ActionEnumTypes';
import * as Types from 'Models/CheckoutModel';
import { REQUEST_STATUS } from 'Components/shared/enums';

export type State = Types.CheckoutStore;

const { items, amountNet, amountGross, amountItemsGross, voucherGross, voucherNet, voucher } = JSON.parse(sessionStorage.getItem('order')) || {};

export const initialState: State = {
  checkoutItems: items || [],
  amountGross: amountGross || 0,
  amountNet: amountNet || 0,
  amountItemsGross: amountItemsGross || 0,
  amountItemsNet: 0,
  voucherGross: voucherGross || 0,
  voucherNet: voucherNet || 0,
  voucher: {
    data: voucher || {},
    status: REQUEST_STATUS.IDLE,
  },
};

export function checkoutReducer(state: any = initialState, action) {
  switch (action.type) {
    case ActionTypes.Checkout.ADD_CHECKOUT_ITEM: {
      const newArray = [...state.checkoutItems, action.payload.checkoutItem];
      if (newArray.length <= Number(process.env.REACT_APP_PRINTS_MAX_AMOUNT)) {
        return {
          ...state,
          // checkoutItems: [...state.checkoutItems, action.payload.checkoutItem],
          checkoutItems: [...newArray],
        };
      } else return { ...state };
    }
    case ActionTypes.Checkout.EDIT_CHECKOUT_ITEM: {
      const newData = state.checkoutItems;
      const idx = newData.findIndex(({ uuid }) => uuid === action.payload.checkoutItem.uuid);
      newData[idx] = action.payload.checkoutItem;
      return {
        ...state,
        checkoutItems: [...newData],
      };
    }
    case ActionTypes.Checkout.DELETE_CHECKOUT_ITEM: {
      const newData = state.checkoutItems;
      const idx = newData.findIndex(({ uuid }) => uuid === action.payload.uuid);
      newData.splice(idx, 1);
      return {
        ...state,
        checkoutItems: [...newData],
      };
    }
    case ActionTypes.Checkout.CALCULATE_CHECKOUT_PRICE: {
      const {
        checkoutItems,
        voucher: { data },
      } = state;

      let amountNet = Number(checkoutItems.reduce((a, { quantity, price_net }) => a + quantity * price_net, 0).toFixed(2));
      let voucherGross = 0;
      let voucherNet = 0;

      const vat = Number(process.env.REACT_APP_VAT_VALUE) / 100;

      const amountItemsNet = amountNet;
      const amountItemsGross = Number((amountNet * (1 + vat)).toFixed(2));

      // if voucher
      if (data && data.value) {
        voucherNet = Number(((amountNet * data.value) / 100).toFixed(2));
        voucherGross = Number((((amountNet * data.value) / 100) * (1 + vat)).toFixed(2));
        amountNet = Number((amountNet - (amountNet * data.value) / 100).toFixed(2));
      }

      let amountGross = Number((amountNet * (1 + vat)).toFixed(2));

      const { activeDeliveryMethod } = action.payload;
      if (activeDeliveryMethod.price_gross && activeDeliveryMethod.price_net) {
        amountNet = Number((amountNet + activeDeliveryMethod.price_net).toFixed(2));
        // amountGross = Number((amountGross + activeDeliveryMethod.price_gross).toFixed(2));
        amountGross = Number((amountNet * (1 + vat)).toFixed(2));
      }

      return {
        ...state,
        amountGross,
        amountNet,
        amountItemsGross,
        voucherGross,
        voucherNet,
        amountItemsNet,
      };
    }
    case ActionTypes.Checkout.GET_CHECKOUT_ITEMS: {
      return {
        ...state,
        checkoutItems: action.payload.checkoutItems,
      };
    }
    case ActionTypes.Checkout.GET_VOUCHER_INIT: {
      return {
        ...state,
        voucher: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case ActionTypes.Checkout.GET_VOUCHER_SUCCESS: {
      return {
        ...state,
        voucher: {
          data: action.payload.voucher,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case ActionTypes.Checkout.GET_VOUCHER_FAILURE: {
      return {
        ...state,
        voucher: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case ActionTypes.Global.CLEAR_STORE: {
      return {
        ...state,
        checkoutItems: [],
        amountGross: 0,
        amountNet: 0,
        voucherGross: 0,
        amountItemsGross: 0,
        voucherNet: 0,
        voucher: {
          data: undefined,
          status: REQUEST_STATUS.IDLE,
        },
      };
    }
    case ActionTypes.Checkout.ADD_MULTIPLE_CHECKOUT_ITEMS: {
      const newArray = [...state.checkoutItems, ...action.payload.checkoutItems];
      if (newArray.length <= Number(process.env.REACT_APP_PRINTS_MAX_AMOUNT)) {
        return {
          ...state,
          checkoutItems: [...newArray],
        };
      } else return { ...state };
    }
    default: {
      return state;
    }
  }
}
