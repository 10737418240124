import * as React from 'react';
import background from 'Assets/Img/bg.png';
import frame from 'Assets/Img/frame.png';
import { Link } from 'react-router-dom';
import icon2 from 'Assets/Img/Icon edit.svg';
import icon4 from 'Assets/Img/Icon photos.svg';
import icon3 from 'Assets/Img/Icon shopping-cart.svg';
import icon1 from 'Assets/Img/Icon upload.svg';

// import AddImageIcon from '../../Assets/Img/add-img-icon.svg';
// import Dropzone from 'react-dropzone';

const LandingPage = () => {
  return (
    <React.Fragment>
      <div className="landing-page">
        <h1>
          <strong>Chwile</strong>
          <br /> w Twoich <br /> rękach
        </h1>
        <img className="landing-page--img bg" src={background} alt="" />
        <img className="landing-page--img frame" src={frame} alt="" />
        <Link className="landing-page--link" to="/photo-print">
          <button className="landing-page__creator-link">Przejdź do kreatora</button>
        </Link>
      </div>
      <footer className="footer">
        <div className="footer__box">
          <div className="footer__icon">
            <img src={icon1} alt="" />
            <p className="footer__icon-name">1. Prześlij</p>
            <p className="footer__icon-descript">Dostosuj swoje zdjęcia i pozostaw nam wydrukowanie ich w najlepszej jakości po czym prześlemy je prosto do Ciebie!</p>
          </div>
        </div>
        <div className="footer__box">
          <div className="footer__icon">
            <img src={icon2} alt="" />
            <p className="footer__icon-name">2. Dostosuj</p>
            <p className="footer__icon-descript">Szybko i łatwo dostosuj swoje zdjęcia w naszym kreatorze, wybierz ilość i rodzaj papieru, my zajmiemy się resztą</p>
          </div>
        </div>
        <div className="footer__box">
          <div className="footer__icon">
            <img src={icon3} alt="" />
            <p className="footer__icon-name">3. Zamów</p>
            <p className="footer__icon-descript">
              Dodaj zdjęcia do koszyka i zamów <br />
              prosto do siebie
            </p>
          </div>
        </div>
        <div className="footer__box">
          <div className="footer__icon">
            <img src={icon4} alt="" />
            <p className="footer__icon-name">4. Odbierz</p>
            <p className="footer__icon-descript">Odbierz profesjonalnie wydrukowane zdjęcia i ciesz się uchwyconymi chwilami</p>
          </div>
        </div>
      </footer>
    </React.Fragment>
  );
};

export default LandingPage;
