import * as React from 'react';

const Counter = ({ label, onChange, quantity, minVal = 1 }: CounterP) => {
  const [counterValue, setCounterValue] = React.useState(quantity);
  const [timer, setTimer] = React.useState(undefined);
  const onAdd = () => {
    const value = quantity + 1;
    setCounterValue(value);
    onChange(value);
  };
  const onReduce = () => {
    const value = quantity - 1;
    setCounterValue(value);
    onChange(value);
  };

  // let timer: any;
  const handleChange = ({ target: { value } }) => {
    clearTimeout(timer);
    setCounterValue(parseInt(value));
    if (value === '' || value === '0' || value <= 0) {
      onChange(0);
      setTimer(
        setTimeout(() => {
          setCounterValue(1);
          onChange(1);
        }, 1500),
      );
    } else {
      onChange(parseInt(value));
    }
  };

  return (
    <div className="counter">
      {label && <div className="counter__label">{label}</div>}

      <div className="counter__inner">
        <div className="counter__btn counter__reduce" onClick={() => quantity > minVal && onReduce()}>
          -
        </div>
        <div className="counter__value">
          <input className="counter__input" type="number" value={counterValue} onChange={handleChange} />
        </div>
        <div className="counter__btn counter__add" onClick={onAdd}>
          +
        </div>
      </div>
    </div>
  );
};

export default Counter;

type CounterP = {
  label?: string;
  onChange: (value) => void;
  quantity: number;
  minVal?: string | number;
};
