import * as React from 'react';
import Button from 'Components/shared/Button';
import addPictureIcon from 'Assets/Img/add-picture-icon.svg';
import { SVG_TYPE } from 'Components/shared/enums';

const ActionBarCustomizationPanel = ({ goBack, checkoutItemsAmount, openCheckout, isSelectionMode, showFraming }: ActionBarCustomizationPanelP) => (
  <div className="action-bar action-bar--customizationPanel">
    <div className="action-bar--l">
      <Button label={'Wróć do albumu'} svgName={SVG_TYPE.CHEVRON_LEFT} iconPlacement="left" classNames={'btn--black btn--back'} onClick={goBack} />
      {/* <Button label={'Nowa konfiguracja'} iconPlacement="left" iconName={addPictureIcon} classNames={'btn--black btn--new-configuration'} /> */}
      {isSelectionMode ? (
        <div className="action-bar__title">Tryb dostosowania wielu zdjęć</div>
      ) : (
        <Button label={'Kadruj'} iconPlacement="left" iconName={addPictureIcon} classNames={'btn--black'} onClick={showFraming} />
      )}
    </div>
    <div className="action-bar--r">
      <Button label={`Koszyk: ${checkoutItemsAmount}`} svgName={SVG_TYPE.CART} iconPlacement="right" classNames={'btn--yellow btn-basket'} onClick={openCheckout} disabled={!checkoutItemsAmount} />
    </div>
  </div>
);

export default ActionBarCustomizationPanel;

type ActionBarCustomizationPanelP = {
  goBack: () => void;
  openCheckout: () => void;
  checkoutItemsAmount: number;
  isSelectionMode: boolean;
  showFraming: () => void;
};
