import React, { useState } from 'react';
import { ActionBarCustomizationPanel, SliderGallery, CustomizationPanelFooter, SelectedPhotosGallery } from './';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import Button from 'Components/shared/Button';
import addPictureIcon from 'Assets/Img/add-picture-icon.svg';

const CustomizationPanel = ({
  goBack,
  activePhoto,
  activePhotoIndex,
  photos,
  initialSlide,
  setActivePhotoId,
  deleteActivePhoto,
  checkoutItemsAmount,
  openCheckout,
  saveCroppedImage,
  isSelectionMode,
  selectedPhotosData,
  showFraming,
  hideFraming,
  isFraming,
}: CustomizationPanelP) => {
  // const [isFraming, setIsFraming] = useState(false);
  const [cropper, setCropper] = useState<any>();

  const getCropData = async () => {
    if (typeof cropper !== 'undefined') {
      cropper.getCroppedCanvas().toBlob(async (blob) => {
        const formData = new FormData();
        formData.append('image', blob);
        await saveCroppedImage({ src: cropper.getCroppedCanvas().toDataURL(), ...cropper.getCanvasData() }, formData);
      });
    }
  };

  const rotateRight = () => {
    cropper.rotate(10);
  };
  const rotateLeft = () => {
    cropper.rotate(-10);
  };
  const zoomIn = () => {
    cropper.zoom(0.1);
  };
  const zoomOut = () => {
    cropper.zoom(-0.1);
  };
  const setMove = () => {
    cropper.setDragMode('move');
  };
  const setCrop = () => {
    cropper.setDragMode('crop');
  };

  return (
    <div className="customizationPanel">
      <div className="container">
        <div className="customizationPanel__inner">
          <ActionBarCustomizationPanel goBack={goBack} checkoutItemsAmount={checkoutItemsAmount} openCheckout={openCheckout} showFraming={showFraming} isSelectionMode={isSelectionMode} />
          {isSelectionMode ? (
            <SelectedPhotosGallery selectedPhotosData={selectedPhotosData} />
          ) : (
            <>
              <SliderGallery photos={photos} activePhoto={activePhoto} initialSlide={initialSlide} setActivePhotoId={setActivePhotoId} isFraming={isFraming} setCropper={setCropper} />
              <CustomizationPanelFooter activePhotoName={activePhoto?.name} deleteActivePhoto={deleteActivePhoto} />
            </>
          )}
        </div>
        {isFraming ? (
          <div className="customizationPanel__framing">
            <div className="customizationPanel__framing-header">
              <div className="customizationPanel__framing-header--l">
                <Button
                  label={'Zapisz'}
                  iconPlacement="left"
                  iconName={addPictureIcon}
                  classNames={'btn--black '}
                  onClick={() => {
                    hideFraming();
                    getCropData();
                  }}
                />
                <Button label={'Anuluj'} iconPlacement="left" classNames={'btn--black '} onClick={hideFraming} />
              </div>
              <div className="customizationPanel__framing-header--r">
                <Button iconClassName={'fa-arrows-alt'} classNames={'btn--clean'} onClick={setMove} title={'Zmień pozycję'} />
                <Button iconClassName={'fa-crop-alt'} classNames={'btn--clean'} onClick={setCrop} title={'Zaznacz'} />
                <Button iconClassName={'fa-search-minus'} classNames={'btn--clean'} onClick={zoomOut} title={'Zmniejsz'} />
                <Button iconClassName={'fa-search-plus'} classNames={'btn--clean'} onClick={zoomIn} title={'Powiększ'} />
                <Button iconClassName={'fa-undo'} classNames={'btn--clean'} onClick={rotateLeft} title={'Obróć w lewo'} />
                <Button iconClassName={'fa-redo'} classNames={'btn--clean'} onClick={rotateRight} title={'Obróć w prawo'} />
              </div>
            </div>
            <Cropper
              src={photos[activePhotoIndex].src}
              style={{ height: '100%', width: '100%' }}
              initialAspectRatio={16 / 9}
              background={false}
              movable
              ref={cropper}
              guides={true}
              onInitialized={(instance) => {
                setCropper(instance);
              }}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default CustomizationPanel;

type CustomizationPanelP = {
  goBack: () => void;
  activePhoto: {
    src: string;
    name: string;
  };
  photos: Array<any>;
  initialSlide?: number;
  setActivePhotoId: any;
  deleteActivePhoto: () => void;
  openCheckout: () => void;
  checkoutItemsAmount: number;
  saveCroppedImage: (image, formData) => void;
  activePhotoIndex: number;
  isSelectionMode: boolean;
  selectedPhotosData: boolean;
  showFraming: () => void;
  hideFraming: () => void;
  isFraming: boolean;
};
