export enum User {
  LOG_IN = 'LOG_IN',
  LOG_IN_SUCCESS = 'LOG_IN_SUCCESS',
  LOG_IN_FAILURE = 'LOG_IN_FAILURE',
  SEND_MESSAGE_INIT = 'SEND_MESSAGE_INIT',
  SEND_MESSAGE_SUCCESS = 'SEND_MESSAGE_SUCCESS',
  SEND_MESSAGE_FAILURE = 'SEND_MESSAGE_FAILURE',
}

export enum ViewManagement {
  SHOW_OUTER_BANNER = '@@ViewManagement/SHOW_OUTER_BANNER',
  HIDE_OUTER_BANNER = '@@ViewManagement/HIDE_OUTER_BANNER',
  SET_PREV_PATH = '@@ViewManagement/SET_PREV_PATH',
  SET_MENU_EXPANDED = '@@ViewManagement/SET_MENU_EXPANDED',
  SET_RETRY_PAYMENT = '@@ViewManagement/SET_RETRY_PAYMENT',
  MANAGE_SELECTION_MODE = '@@ViewManagement/MANAGE_SELECTION_MODE',
  SHOW_FRAMING = '@@ViewManagement/SHOW_FRAMING',
  HIDE_FRAMING = '@@ViewManagement/HIDE_FRAMING',
}

export enum Prints {
  GET_PHOTOS = '@@prints/GET_PHOTOS',
  SET_ACTIVE_PHOTO_ID = '@@prints/SET_ACTIVE_PHOTO_ID',
  CHANGE_LOADING_STATUS = '@@prints/CHANGE_LOADING_STATUS',
  DELETE_ACTIVE_PHOTO = '@@prints/DELETE_ACTIVE_PHOTO',
  GET_ATTRIBUTE_VALUES = '@@prints/GET_ATTRIBUTE_VALUES',
  GET_PRODUCTS = '@@prints/GET_PRODUCTS',
  SET_PROGRESS_PERCENTAGE = '@@prints/SET_PROGRESS_PERCENTAGE',
  SET_FILE_UUID = '@@prints/SET_FILE_UUID',
  SAVE_CROPPED_IMAGE = '@@prints/SAVE_CROPPED_IMAGE',
  ADD_SELECTED_PHOTO = '@@prints/ADD_SELECTED_PHOTO',
  UNCHECK_SELECTED_PHOTO = '@@prints/UNCHECK_SELECTED_PHOTO',
  UNCHECK_SELECTED_PHOTOS = '@@prints/UNCHECK_SELECTED_PHOTOS',
  DELETE_SELECTED_PHOTOS = '@@prints/DELETE_SELECTED_PHOTOS',
}

export enum Checkout {
  GET_CHECKOUT_ITEMS = '@@checkout/GET_CHECKOUT_ITEMS',
  ADD_CHECKOUT_ITEM = '@@checkout/ADD_CHECKOUT_ITEM',
  EDIT_CHECKOUT_ITEM = '@@checkout/EDIT_CHECKOUT_ITEM',
  DELETE_CHECKOUT_ITEM = '@@checkout/DELETE_CHECKOUT_ITEM',
  GET_VOUCHER_INIT = '@@checkout/GET_VOUCHER_INIT',
  GET_VOUCHER_SUCCESS = '@@checkout/GET_VOUCHER_SUCCESS',
  GET_VOUCHER_FAILURE = '@@checkout/GET_VOUCHER_FAILURE',
  CALCULATE_CHECKOUT_PRICE = '@@checkout/CALCULATE_CHECKOUT_PRICE',
  ADD_MULTIPLE_CHECKOUT_ITEMS = '@@checkout/ADD_MULTIPLE_CHECKOUT_ITEMS',
}

export enum Delivery {
  GET_METHODS_INIT = '@@delivery/GET_METHODS_INIT',
  GET_METHODS_SUCCESS = '@@delivery/GET_METHODS_SUCCESS',
  GET_METHODS_FAILURE = '@@delivery/GET_METHODS_FAILURE',
  SET_ACTIVE_METHOD = '@@delivery/SET_ACTIVE_METHOD',
}

export enum Order {
  SET_ORDER_INIT = '@@delivery/SET_ORDER_INIT',
  SET_ORDER_SUCCESS = '@@delivery/SET_ORDER_SUCCESS',
  SET_ORDER_FAILURE = '@@delivery/SET_ORDER_FAILURE',
  GET_PAYMENT_STATUS_INIT = '@@delivery/GET_PAYMENT_STATUS_INIT',
  GET_PAYMENT_STATUS_SUCCESS = '@@delivery/GET_PAYMENT_STATUS_SUCCESS',
  GET_PAYMENT_STATUS_FAILURE = '@@delivery/GET_PAYMENT_STATUS_FAILURE',
}

export enum Global {
  CLEAR_STORE = '@@global/CLEAR_STORE',
}
