import { connect } from 'react-redux';
import Main from './Main';
import { bindActionCreators } from 'redux';
import { selectors, actions } from 'Modules/CheckoutModule';
import { getVoucher } from 'Store/Actions/Checkout';
import { RootState } from 'Store/Reducers/RootReducer';
import { REQUEST_STATUS } from 'Components/shared/enums';

type ConnectedP = {
  checkoutItems: Array<any>;
  voucher: any;
  voucherStatus: REQUEST_STATUS;
  checkoutPrices: any;
};

type DispatchedP = {
  actions: {
    deleteCheckoutItem: (uuid) => void;
    getVoucher: (voucher) => void;
  };
};

export type MainType = ConnectedP & DispatchedP;

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      deleteCheckoutItem: actions.deleteCheckoutItem,
      getVoucher: getVoucher,
    },
    dispatch,
  ),
});

const mapStateToProps = (state: RootState): ConnectedP => ({
  checkoutItems: selectors.getCheckoutItems(state),
  checkoutPrices: selectors.getCheckoutPrices(state),
  voucher: selectors.getVoucher(state),
  voucherStatus: selectors.getVoucherStatus(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);
