import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Main from './Main';
import { actions, selectors } from 'Modules/PrintModule';
import { selectors as CheckoutSelectors } from 'Modules/CheckoutModule';
import { actions as viewManagementActions, selectors as viewManagementSelectors } from 'Modules/ViewManagementModule';

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators(
    {
      getPhotoPrint: actions.getPhotoPrints,
      setActivePhotoId: actions.setActivePhotoId,
      showOuterBanner: viewManagementActions.showOuterBanner,
      hideOuterBanner: viewManagementActions.hideOuterBanner,
      deleteActivePhoto: actions.deleteActivePhotoSuccess,
      saveCroppedImage: actions.saveCroppedImage,
      addSelectedPhoto: actions.addSelectedPhoto,
      deleteSelectedPhoto: actions.deleteSelectedPhoto,
      clearSelectedPhotos: actions.clearSelectedPhotos,
      deleteSelectedPhotos: actions.deleteSelectedPhotos,
      manageSelectionMode: viewManagementActions.manageSelectionMode,
      showFraming: viewManagementActions.showFraming,
      hideFraming: viewManagementActions.hideFraming,
    },
    dispatch,
  ),
});

const mapStateToProps = (state) => ({
  photos: selectors.getPhotos(state),
  activePhoto: selectors.getActivePhotoDetails(state),
  activePhotoIndex: selectors.getActivePhotoDetailsIndex(state),
  initialSlide: selectors.getActivePhotoDetailsIndex(state),
  checkoutItems: CheckoutSelectors.getCheckoutItems(state),
  isOuterBannerVisible: viewManagementSelectors.isOuterBannerVisible(state),
  prevPath: viewManagementSelectors.getPrevPath(state),
  selectedPhotos: selectors.getSelectedPhotos(state),
  isSelectionMode: viewManagementSelectors.getIsSelectionMode(state),
  selectedPhotosData: selectors.getSelectedPhotosData(state),
  isFraming: viewManagementSelectors.getIsFraming(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);
