import * as Types from '../../Models/OrderModel';
import * as ActionTypes from '../Actions/EnumTypes/ActionEnumTypes';
import { REQUEST_STATUS } from 'Components/shared/enums';

export type State = Types.OrderStore;

export const initialState: State = {
  order: {
    data: undefined,
    status: REQUEST_STATUS.IDLE,
  },
  paymentStatus: {
    data: undefined,
    status: REQUEST_STATUS.IDLE,
  },
};

export function orderReducer(state: State = initialState, action) {
  switch (action.type) {
    case ActionTypes.Order.SET_ORDER_INIT: {
      return {
        ...state,
        order: {
          data: undefined,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case ActionTypes.Order.SET_ORDER_SUCCESS: {
      return {
        ...state,
        order: {
          data: action.payload.order,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case ActionTypes.Order.SET_ORDER_FAILURE: {
      return {
        ...state,
        order: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    case ActionTypes.Order.GET_PAYMENT_STATUS_INIT: {
      return {
        ...state,
        paymentStatus: {
          ...state.paymentStatus,
          status: REQUEST_STATUS.INIT,
        },
      };
    }
    case ActionTypes.Order.GET_PAYMENT_STATUS_SUCCESS: {
      return {
        ...state,
        paymentStatus: {
          data: action.payload.paymentStatus,
          status: REQUEST_STATUS.SUCCESS,
        },
      };
    }
    case ActionTypes.Order.GET_PAYMENT_STATUS_FAILURE: {
      return {
        ...state,
        paymentStatus: {
          data: undefined,
          status: REQUEST_STATUS.FAILURE,
        },
      };
    }
    default: {
      return state;
    }
  }
}
