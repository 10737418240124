import { createSelector } from 'reselect';

const getViewManagementStore = (state) => state.viewManagementStore;
const isOuterBannerVisible = createSelector(getViewManagementStore, (state) => state.outerBannerVisible);
const getPrevPath = createSelector(getViewManagementStore, (state) => state.prevPath);
const getIsMenuExpanded = createSelector(getViewManagementStore, (state) => state.isMenuExpanded);
const getIsRetryPayment = createSelector(getViewManagementStore, (state) => state.isRetryPayment);
const getIsSelectionMode = createSelector(getViewManagementStore, (state) => state.isSelectionMode);
const getIsFraming = createSelector(getViewManagementStore, (state) => state.isFraming);

export { isOuterBannerVisible, getPrevPath, getIsMenuExpanded, getIsRetryPayment, getIsSelectionMode, getIsFraming };
