import { connector } from '../../../Modules/OrderModule';
import { Order } from '../EnumTypes/ActionEnumTypes';
import { getCheckoutItems, getCheckoutPrices, getVoucher } from 'Store/Selectors/checkout';
import { getActiveMethod } from 'Store/Selectors/delivery';

const init = () => ({
  type: Order.SET_ORDER_INIT,
});

const success = (order: any) => ({
  type: Order.SET_ORDER_SUCCESS,
  payload: {
    order,
  },
});

const failure = () => ({
  type: Order.SET_ORDER_FAILURE,
});

const setOrder = (userData, isCompany, delivery_details) => async (dispatch, getState) => {
  dispatch(init());
  try {
    const checkoutItems = getCheckoutItems(getState());
    const { amountGross, amountNet, amountItemsGross, voucherGross } = getCheckoutPrices(getState());
    const { name } = getVoucher(getState());
    const { id } = getActiveMethod(getState());

    const order = {
      amount_net: amountNet,
      // amount_gross: Number((name ? amountGrossWithVoucher : amountGross).toFixed(2)),
      amount_gross: amountGross,
      voucher: name,
      delivery_method_id: id,
      delivery_details: delivery_details[0],
      items: checkoutItems.map(({ file_uuid, product_variant_id, price_net, quantity }) => ({ file_uuid, product_variant_id, price_net, quantity })),
      customer: {
        ...userData,
        company_data: isCompany ? { nip: userData.nip, name: userData.name, zip_code: userData.company_zip_code, city: userData.company_city, address: userData.company_address } : null,
      },
    };
    if (isCompany) {
      delete order.customer.nip;
      delete order.customer.name;
      delete order.customer.company_zip_code;
      delete order.customer.company_address;
      delete order.customer.company_city;
    }

    const { data } = await connector.sendOrder(order);
    dispatch(success(data));

    sessionStorage.setItem('order', JSON.stringify({ ...data, amountGross, amountNet, amountItemsGross, voucherGross, delivery_details }));

    if (data.payment) {
      window.location.href = data.payment.url;
    }
  } catch (err) {
    await dispatch(failure());
    console.log(err);
  }
};

export default setOrder;
