import React from 'react';
import Dropzone from 'react-dropzone';
import onDragIcon from 'Assets/Img/on-drag-icon.svg';

type P = {
  getPhotoPrint: (file) => void;
  setDragEnter: (isEntered) => void;
  isDragEntered: boolean;
  innerHeight: number;
};

const DropzoneArea = ({ isDragEntered, setDragEnter, getPhotoPrint, innerHeight }: P) => {
  // const [isDragEntered, setDragEnter] = React.useState(false);
  return (
    <Dropzone
      onDrop={async (file) => {
        await setDragEnter(false);
        getPhotoPrint(file);
      }}
      // onDrop={(files) => {
      //   console.log(files[0]);
      //   setDragEnter(false);
      //   setFieldValue('audio_file', files[0]);
      //   dataForm.append('recording', files[0]);
      // }}

      onDragEnter={() => {
        !isDragEntered && setDragEnter(true);
      }}
      onDragLeave={() => {
        isDragEntered && setDragEnter(false);
      }}
      accept="image/*">
      {({ getRootProps, getInputProps }) => (
        <>
          <div
            {...getRootProps({ className: `dropzone dropzone-area dropzone-area${isDragEntered ? '--entered' : ''}`, onClick: (event) => event.stopPropagation() })}
            style={{ height: innerHeight ? innerHeight + 70 : '100%' }}>
            <input {...getInputProps()} />
          </div>
          {isDragEntered && (
            <div className="dropzone-area__entered-icon">
              <img src={onDragIcon} alt="Opuść plik tutaj" />
            </div>
          )}
        </>
      )}
    </Dropzone>
  );
};

export default DropzoneArea;
