import * as React from 'react';
import { ActionBarPhotoPrints, PhotoPrints, CustomizationPanel } from './components';
import Routes from 'Models/Routes';
import AddPhoto from './components/AddPhoto';
import ROUTES from 'Models/Routes';

class Main extends React.PureComponent<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      isCustomizationPanelVisible: false,
      innerWidth: window.innerWidth,
      dropzoneRef: null,
      // isSelectionMode: false,
    };
    this.resizeScreen = this.resizeScreen.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.resizeScreen);
  }

  resizeScreen() {
    this.setState({ innerWidth: window.innerWidth });
  }

  showPanel = () => {
    this.setState({ isCustomizationPanelVisible: true });
    this.props.history.push(Routes.PHOTO_PRINT_CUSTOMIZE);
    this.props.actions.showOuterBanner();
  };

  hidePanel = () => {
    this.props.history.push(Routes.PHOTO_PRINT);
    this.setState({ isCustomizationPanelVisible: false });
    this.props.actions.hideOuterBanner();
  };

  openCustomization = (_, { photo = {} }: any = {}) => {
    photo && this.props.actions.setActivePhotoId(photo.id);
    this.showPanel();
  };
  openCheckout = () => {
    const { isOuterBannerVisible, prevPath, location } = this.props;
    if (isOuterBannerVisible && prevPath === ROUTES.PHOTO_PRINT_CUSTOMIZE) {
      this.props.history.push(prevPath);
    } else if (isOuterBannerVisible && prevPath === ROUTES.PHOTO_PRINT && location.pathname !== ROUTES.PHOTO_PRINT_CUSTOMIZE) {
      this.props.history.push(prevPath);
      this.props.actions.hideOuterBanner();
    } else {
      this.props.history.push(Routes.PHOTO_PRINT_CHECKOUT);
      this.props.actions.showOuterBanner();
    }
  };
  getDropzoneRef = (ref) => {
    if (!this.state.dropzoneRef) {
      this.setState({ dropzoneRef: ref });
    }
  };

  handleSelectionMode = () => {
    const {
      isSelectionMode,
      actions: { manageSelectionMode, clearSelectedPhotos },
    } = this.props;
    if (isSelectionMode) {
      clearSelectedPhotos();
    }
    manageSelectionMode();
  };

  public render() {
    const {
      photos,
      activePhoto,
      activePhotoIndex,
      initialSlide,
      checkoutItems,
      selectedPhotos,
      isSelectionMode,
      selectedPhotosData,
      isFraming,
      actions: { setActivePhotoId, getPhotoPrint, deleteActivePhoto, saveCroppedImage, addSelectedPhoto, deleteSelectedPhoto, deleteSelectedPhotos, showFraming, hideFraming },
    } = this.props;
    const { isCustomizationPanelVisible, innerWidth } = this.state;

    if (!photos.length && isCustomizationPanelVisible) {
      this.hidePanel();
    }

    return (
      <div className="screen--photo-print">
        <AddPhoto getPhotoPrint={getPhotoPrint} getDropzoneRef={this.getDropzoneRef} photosLength={photos.length} checkoutItemsAmount={checkoutItems.length} openCheckout={this.openCheckout} />
        {photos.length > 0 && (
          <>
            <ActionBarPhotoPrints
              checkoutItemsAmount={checkoutItems.length}
              openCheckout={this.openCheckout}
              photosAmount={photos.length}
              dropzoneRef={this.state.dropzoneRef}
              handleSelectionMode={this.handleSelectionMode}
              isSelectionMode={isSelectionMode}
              selectedPhotosCount={selectedPhotos.length}
              deleteSelectedPhotos={deleteSelectedPhotos}
              openCustomization={this.openCustomization}
              addSelectedPhoto={addSelectedPhoto}
            />
            <PhotoPrints
              photos={photos}
              onImageClick={this.openCustomization}
              getPhotoPrint={getPhotoPrint}
              isCustomizationPanelVisible={isCustomizationPanelVisible}
              innerWidth={innerWidth}
              isSelectionMode={isSelectionMode}
              addSelectedPhoto={addSelectedPhoto}
              deleteSelectedPhoto={deleteSelectedPhoto}
              selectedPhotos={selectedPhotos}
            />
          </>
        )}
        {isCustomizationPanelVisible && photos.length > 0 && (
          <CustomizationPanel
            openCheckout={this.openCheckout}
            checkoutItemsAmount={checkoutItems.length}
            photos={photos}
            goBack={this.hidePanel}
            activePhoto={activePhoto}
            initialSlide={initialSlide}
            setActivePhotoId={setActivePhotoId}
            deleteActivePhoto={deleteActivePhoto}
            saveCroppedImage={saveCroppedImage}
            activePhotoIndex={activePhotoIndex}
            isSelectionMode={isSelectionMode}
            selectedPhotosData={selectedPhotosData}
            showFraming={showFraming}
            hideFraming={hideFraming}
            isFraming={isFraming}
          />
        )}
      </div>
    );
  }
}

export default Main;
