import React from 'react';
import left from 'Assets/Img/upload_photo_left.png';
import right from 'Assets/Img/upload_photo_right.png';
import upload from 'Assets/Img/upload.svg';
import onDragIcon from 'Assets/Img/on-drag-icon.svg';
import Dropzone from 'react-dropzone';
import Button from 'Components/shared/Button';
import addPictureIcon from 'Assets/Img/add-picture-icon.svg';
import { SVG_TYPE } from 'Components/shared/enums';

type P = {
  getPhotoPrint: (file) => void;
  getDropzoneRef: (ref) => void;
  openCheckout: () => void;
  photosLength: number;
  checkoutItemsAmount: any;
};
const AddPhoto = ({ photosLength, checkoutItemsAmount, openCheckout, getDropzoneRef, getPhotoPrint }: P) => {
  const [isDragEntered, setDragEnter] = React.useState(false);
  return (
    <div className={`addPhoto addPhoto__wrapper ${photosLength !== 0 && 'addPhoto__wrapper--hide'}`}>
      <img className="addPhoto__images" src={left} alt="Tło" />
      <img className="addPhoto__images2" src={right} alt="Tło" />
      {checkoutItemsAmount ? (
        <div className="addPhoto__checkout">
          <Button label={`Koszyk: ${checkoutItemsAmount}`} svgName={SVG_TYPE.CART} iconPlacement="right" classNames={'btn--yellow btn-basket'} onClick={openCheckout} />
        </div>
      ) : null}
      <div className="addPhoto__upload">
        <Dropzone
          ref={(node) => {
            getDropzoneRef(node);
          }}
          onDrop={(file) => {
            getPhotoPrint(file);
            setDragEnter(false);
          }}
          onDragEnter={() => setDragEnter(!isDragEntered)}
          onDragLeave={() => setDragEnter(!isDragEntered)}
          accept=".jpg"
          >
          {({ getRootProps, getInputProps }) => (
            <>
              <div {...getRootProps({ className: `dropzone addPhoto__upload--block addPhoto__upload${isDragEntered ? '--entered' : ''}` })}>
                <input {...getInputProps()} type="file" />
                <img src={upload} alt="" className="" />
                <h1>
                  Dodaj swoje ulubione zdjęcia <br /> które chcesz wydrukować
                </h1>
                <span className="text">
                  przerzuć zdjęcia tutaj <br /> lub
                </span>
                <div className="center">
                  <Button label={'Dodaj zdjęcie z dysku'} iconName={addPictureIcon} classNames={'btn--gray'} iconPlacement="left" />
                </div>
              </div>
              {isDragEntered && (
                <div className="addPhoto__entered-icon">
                  <img src={onDragIcon} alt="Opuść plik tutaj" />
                </div>
              )}
            </>
          )}
        </Dropzone>
      </div>
    </div>
  );
};

export default AddPhoto;
