import * as React from 'react';
import { Formik, Field } from 'formik';
import RegularField from '../shared/form/regularField/regularField';
import Button from 'Components/shared/Button';
import * as Yup from 'yup';
import SVG from 'Components/shared/SVG';
import { SVG_TYPE } from 'Components/shared/enums';
import { connect } from 'react-redux';
import { Actions, Selectors } from 'Modules/UserModule';
import { REQUEST_STATUS } from 'Components/shared/enums';
import { useSnackbar } from 'react-simple-snackbar';
import ReCAPTCHA from 'react-google-recaptcha';

export const contactValidation = Yup.object().shape({
  first_name: Yup.string().required('Pole wymagane'),
  last_name: Yup.string().required('Pole wymagane'),
  email: Yup.string().email('Niepoprawny email').required('Pole wymagane'),
  message: Yup.string().required('Pole wymagane'),
});

const Contact = ({ contactFormStatus, sendMessage }: ConnectedP & DispatchedP) => {
  const [isRecaptcha, setIsRecaptcha] = React.useState(false);
  const isLoading = () => contactFormStatus === REQUEST_STATUS.INIT;
  const options = {
    position: window.innerWidth >= 681 ? 'top-right' : 'bottom-center',
    style: {
      marginTop: '100px',
    },
  };
  const [openSnackbar] = useSnackbar(options);
  const handleSubmit = async (values, { resetForm }) => {
    if (isRecaptcha) {
      if (await sendMessage(values)) {
        openSnackbar('Pomyślnie wysłano wiadomość');
        resetForm({});
      }
    }
  };

  const onChange = (value) => {
    console.log(value);
    value && setIsRecaptcha(value);
  };
  return (
    <div className="contact">
      <div className="contact__section contact__section--gray">
        <h1 className="contact__title">Napisz do nas</h1>
        <div className="contact__form-wrapper">
          <Formik initialValues={{ first_name: '', last_name: '', email: '', message: '' }} validationSchema={contactValidation} onSubmit={handleSubmit}>
            {({ handleSubmit, errors }) => (
              <>
                <form onSubmit={handleSubmit} className="contact__form">
                  <div className="contact__form--field">
                    <p className="form__data--headingSmall">Imię</p>
                    <Field name="first_name" type="text" component={RegularField} errors={errors} />
                  </div>
                  <div className="contact__form--field">
                    <p className="form__data--headingSmall">Nazwisko</p>
                    <Field name="last_name" type="text" component={RegularField} errors={errors} />
                  </div>
                  <div className="contact__form--field">
                    <p className="form__data--headingSmall">Email</p>
                    <Field name="email" type="text" component={RegularField} errors={errors} />
                  </div>
                  <div className="contact__form--field">
                    <p className="form__data--headingSmall">Treść wiadomości</p>
                    <Field name="message" type="textarea" component={RegularField} errors={errors} />
                  </div>
                  <div className="contact__recaptcha">
                    <ReCAPTCHA sitekey={process.env.REACT_APP_RECAPTCHA_CLIENT_KEY} onChange={onChange} />
                  </div>
                  <Button type="submit" label="Wyślij" classNames={`btn--yellow`} isLoading={isLoading()} />
                </form>
              </>
            )}
          </Formik>
        </div>
      </div>
      <div className="contact__section contact__section">
        <h1 className="contact__title">Dane Kontaktowe</h1>
        <div className="contact__box-wrapper">
          <div className="contact__box">
            <p className="contact__box--subtitle">Firma</p>
            <p className="contact__box--text">know.it Konrad Dembczyński </p>
            <p className="contact__box--text">ul. Lubelska 29/37</p>
            <p className="contact__box--text"> 71-043 Szczecin </p>
            <p className="contact__box--text">NIP: 851 295 80 06</p>
          </div>
          <div className="contact__box">
            <p className="contact__box--subtitle">Obsługa klienta</p>
            <p className="contact__box--text">Dostępna od 6:00-18:00 od poniedziałku do piątku</p>
            <p className="contact__box--text">
              Adres email: <a href="mailto:biuro@ephoto.pl">biuro@ephoto.pl</a>
            </p>
            <div className="contact__phone-wrapper">
              <div className="contact__icon">
                <SVG className={'btn__icon'} type={SVG_TYPE.PHONE} />
              </div>
              <div className="contact__number">605 347 265</div>
            </div>
          </div>
        </div>
      </div>
      <div className="contact__section contact__section--gray">
        <h1 className="contact__title">Pliki do pobrania</h1>
        <div className="contact__box-wrapper">
          <a href={'regulamin.pdf'} rel="noopener noreferrer" target="_blank" className="contact__pdf-box">
            <div className="contact__pdf-box--icon">
              <SVG className={'btn__icon'} type={SVG_TYPE.PDF} />
            </div>
            <div className="contact__pdf-box--data">
              <div className="title">Regulamin serwisu</div>
              <div className="description">Załącznik</div>
            </div>
            <div className="contact__pdf-box--icon">
              <SVG className={'btn__icon'} type={SVG_TYPE.DOWNLOAD} />
            </div>
          </a>
        </div>
      </div>
    </div>
  );
};

type ConnectedP = {
  contactFormStatus: REQUEST_STATUS;
};

type DispatchedP = {
  sendMessage: (data) => any;
};

const mapDispatchToProps: DispatchedP = {
  sendMessage: (data) => Actions.sendMessage(data),
};

const mapStateToProps = (state): ConnectedP => ({
  contactFormStatus: Selectors.getContactFormStatus(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(Contact);
