const calculatePhotoPrintSizes = (ratios) => {
  if (!ratios) {
    return {};
  }
  const [widthRatio, heightRatio] = ratios.split('x').map((e) => parseInt(e));
  const regular = {
    width: 31,
    height: 31,
  };
  if (!widthRatio || !heightRatio) {
    return {};
  }
  let angle;
  let proportion;
  if (widthRatio > heightRatio) {
    proportion = heightRatio / widthRatio;
    const w = regular.width;
    const h = regular.height * proportion;
    angle = Math.atan2(w, h);
    return {
      width: w,
      height: h,
      angle,
    };
  }
  proportion = widthRatio / heightRatio;
  const w = regular.width * proportion;
  const h = regular.height;
  angle = Math.atan2(w, h);
  return {
    width: w,
    height: h,
    angle,
  };
};

export default calculatePhotoPrintSizes;
