import axios, { AxiosRequestConfig } from 'axios';

const axiosPlugin = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

axiosPlugin.defaults.headers.post['Content-Type'] = 'application/json';

axiosPlugin.interceptors.request.use(
  (config: AxiosRequestConfig) => {
    return config;
  },
  error => {
    Promise.reject(error);
  },
);

axiosPlugin.interceptors.response.use(
  response => {
    return Promise.resolve(response);
  },
  error => {
    return Promise.reject(error);
  },
);

export const config = (params?: any, headers?: any) => {
  const configRq: AxiosRequestConfig = {};
  if (params) {
    configRq.params = params;
  }
  if (headers) {
    configRq.headers = headers;
  }
  return configRq;
};

export default axiosPlugin;
