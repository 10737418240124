import * as React from 'react';
import Logo from 'Assets/Img/logo.png';
import * as H from 'history';
import { Link } from 'react-router-dom';
import Routes from 'Models/Routes';
import bg from 'Assets/Img/customization_bg.png';
import { Navigation } from './components';
import { MainType } from './Container';

type PropsLocation = {
  location?: H.Location;
};

const Header = ({ location, isMenuExpanded, actions: { setMenuExpanded } }: PropsLocation & MainType) => {
  const { pathname } = location;
  const onModifyLocalization = [Routes.ORDER, Routes.DELIVERY, Routes.PAYMENT_ACCEPTED];
  const isModified = onModifyLocalization.includes(pathname);
  const hideMenu = () => {
    setMenuExpanded(false);
  };
  return (
    <header className={`header header__inner--${pathname === '/' ? 'landing-page' : 'main'} ${isModified ? 'header__modified' : ''}`}>
      <div className={`header__inner `}>
        {isModified && <img className="header__asset" src={bg} alt="Asset" />}
        <Link to="/" onClick={hideMenu}>
          <img src={Logo} alt="Logo" className={`header__logo ${isMenuExpanded ? 'header__logo--modified' : ''}`} />
        </Link>
        <button
          className={`hamburger ${isMenuExpanded ? 'hamburger--active' : ''}`}
          onClick={() => {
            setMenuExpanded(!isMenuExpanded);
          }}>
          <span className="hamburger__box">
            <span className="hamburger__inner"></span>
          </span>
        </button>
        <div className={`mobile-nav ${isMenuExpanded ? 'active' : ''}`}>
          <Navigation hideMenu={hideMenu} />
        </div>
        <div className="header__menu">
          <Navigation hideMenu={hideMenu} />
        </div>
      </div>
    </header>
  );
};

export default Header;
