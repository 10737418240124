import API from './Config';
import { AxiosPromise } from 'axios';
import { store } from '../Store/Store';
import { setProgressPercentage } from '../Store/Actions/PrintsActions';

export function getAttributeValues(): AxiosPromise<any> {
  return API.get(`attribute-values?attribute=papier`);
}
export function getProducts(): AxiosPromise<any> {
  return API.get(`products?category=odbitki`);
}

export function addPhoto(uuid, photo): AxiosPromise<any> {
  return API.post(`files`, photo, {
    onUploadProgress: (progressEvent) => {
      store.dispatch(setProgressPercentage(uuid, (progressEvent.loaded / progressEvent.total) * 100));
    },
  });
}
