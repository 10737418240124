import { connect } from 'react-redux';
import { RootState } from 'Store/Reducers/RootReducer';
import { bindActionCreators } from 'redux';
import Header from './Main';
import { actions as viewManagementActions, selectors as viewManagementSelectors } from 'Modules/ViewManagementModule';

type ConnectedP = {
  isMenuExpanded: boolean;
};

type DispatchedP = {
  actions?: {
    setMenuExpanded: (isMenuExpanded: boolean) => void;
  };
};

export type MainType = ConnectedP & DispatchedP;

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      setMenuExpanded: viewManagementActions.setMenuExpanded,
    },
    dispatch,
  ),
});

const mapStateToProps = (state: RootState): ConnectedP => ({
  isMenuExpanded: viewManagementSelectors.getIsMenuExpanded(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
