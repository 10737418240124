import { connect } from 'react-redux';
import Main from './Main';
import { bindActionCreators } from 'redux';
import { selectors as OrderSelectors, actions as OrderActions } from 'Modules/OrderModule';
import { selectors as deliverySelectors } from 'Modules/DeliveryModule';
import { actions as ViewManagementActions } from 'Modules/ViewManagementModule';
import { RootState } from 'Store/Reducers/RootReducer';
import { Global } from 'Store/Actions/EnumTypes/ActionEnumTypes';

type ConnectedP = {
  deliveryMethods: Array<any>;
  paymentStatus: any;
};

type DispatchedP = {
  actions: {
    getPaymentStatus: (uuid) => void;
    setRetryPayment: (isRetryPayment: boolean) => void;
    clearStore: () => void;
  };
};

export type MainType = ConnectedP & DispatchedP;

const mapDispatchToProps = (dispatch): DispatchedP => ({
  actions: bindActionCreators(
    {
      getPaymentStatus: OrderActions.getPaymentStatus,
      setRetryPayment: ViewManagementActions.setRetryPayment,
      clearStore: () => dispatch({ type: Global.CLEAR_STORE }),
    },
    dispatch,
  ),
});

const mapStateToProps = (state: RootState): ConnectedP => ({
  deliveryMethods: deliverySelectors.getDeliveryMethods(state),
  paymentStatus: OrderSelectors.getPaymentStatus(state),
});

export default connect(mapStateToProps, mapDispatchToProps)(Main);
