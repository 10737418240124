import { Connector } from '../../../Modules/UserModule';
import { User } from '../EnumTypes/ActionEnumTypes';

const init = () => ({
  type: User.SEND_MESSAGE_INIT,
});

const success = () => ({
  type: User.SEND_MESSAGE_SUCCESS,
});

const failure = () => ({
  type: User.SEND_MESSAGE_FAILURE,
});

const sendMessage = (data) => async (dispatch) => {
  dispatch(init());
  try {
    await Connector.sendMessage(data);
    dispatch(success());
    return true;
  } catch (err) {
    await dispatch(failure());
    console.log(err);
    return false;
  }
};

export default sendMessage;
