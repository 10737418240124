import * as React from 'react';
import Counter from 'Components/shared/Counter/Counter';
import { SVG_TYPE } from 'Components/shared/enums';
import SVG from 'Components/shared/SVG';
import { v4 as uuidv4 } from 'uuid';

document.querySelectorAll('.add-to-cart').forEach((button) => {
  button.addEventListener('click', (e) => {
    button.classList.toggle('added');
  });
});

const Footer = ({ printPrice, addCheckoutItem, currentVariant, activePhoto, paperSize, paperType, isSelectionMode, selectedPhotosData, addMultipleCheckoutItems, checkoutItemsLength }: P) => {
  const [quantity, setQuantity] = React.useState(1);
  const [isAdded, setIsAdded] = React.useState(false);

  const handleAddItem = () => {
    const formedItem = {
      uuid: uuidv4(),
      quantity,
      price_net: currentVariant.price_net,
      price_gross: currentVariant.price_gross,
      product_variant_id: currentVariant.id,
      file_uuid: activePhoto.file_uuid,
      photo: activePhoto,
      paperType,
      paperSize,
    };

    setIsAdded(true);
    setTimeout(() => {
      addCheckoutItem(formedItem);
    }, 2000);
    setTimeout(() => {
      setIsAdded(false);
    }, 3500);
  };

  const handleAddMultipleItems = () => {
    const formedItems = selectedPhotosData.map((photo) => {
      return {
        uuid: uuidv4(),
        quantity,
        price_net: currentVariant.price_net,
        price_gross: currentVariant.price_gross,
        product_variant_id: currentVariant.id,
        file_uuid: photo.file_uuid,
        photo,
        paperType,
        paperSize,
      };
    });

    setIsAdded(true);
    setTimeout(() => {
      addMultipleCheckoutItems(formedItems);
    }, 2000);
    setTimeout(() => {
      setIsAdded(false);
    }, 3500);
  };

  const isButtonDisabled =
    printPrice === 0 ||
    quantity < 1 ||
    (isSelectionMode ? selectedPhotosData.length + checkoutItemsLength > Number(process.env.REACT_APP_PRINTS_MAX_AMOUNT) : checkoutItemsLength === Number(process.env.REACT_APP_PRINTS_MAX_AMOUNT));
  return (
    <div className="customizePhotoFooter">
      {isSelectionMode && (
        <div className="customizePhotoFooter__selected-photos-amount">
          <p> ilość zaznaczonych zdjęć:</p> <span className="customizePhotoFooter__selected-photos-amount--bold">{selectedPhotosData.length}</span>
        </div>
      )}
      <div className="customizePhotoFooter__container">
        <div className="customizePhotoFooter__summary">
          <h5>Suma</h5>
          <p className="customizePhotoFooter__summary--price">
            {!printPrice ? '0,00' : isSelectionMode ? (printPrice * quantity * selectedPhotosData.length).toFixed(2) : (printPrice * quantity).toFixed(2)} ZŁ
          </p>
        </div>
        <div className="customizePhotoFooter__count">
          <Counter quantity={quantity} onChange={setQuantity} label={isSelectionMode ? 'Sztuk na zdjęcie' : 'Sztuk'} />
        </div>
        <div className="customizePhotoFooter__actions">
          {/* <Button label={'Dodaj'} svgName={SVG_TYPE.CART} classNames={'btn--yellow'} iconPlacement="right" disabled={printPrice === 0 || quantity < 1} onClick={handleAddItem} /> */}
          <button
            className={`add-to-cart ${isAdded ? 'added' : ''} ${isButtonDisabled ? 'add-to-cart--disabled' : ''}`}
            onClick={!isAdded && !isButtonDisabled ? (isSelectionMode ? handleAddMultipleItems : handleAddItem) : null}
            disabled={isButtonDisabled}>
            <div className="default">Dodaj</div>
            <div className="success">
              <span>Dodano</span> <SVG type={SVG_TYPE.SUCCESS} />
            </div>
            <div className="cart">
              <SVG type={SVG_TYPE.CART} />
            </div>
            <div className="dots"></div>
          </button>
        </div>
      </div>
      {printPrice && (quantity > 1 || isSelectionMode) ? (
        <p className="customizePhotoFooter__price-per-item">
          <span>Cena za sztukę</span> {printPrice} ZŁ
        </p>
      ) : null}
    </div>
  );
};

export default Footer;

type P = {
  printPrice: number;
  currentVariant: any;
  activePhoto: any;
  paperSize: any;
  paperType: any;
  isSelectionMode: boolean;
  checkoutItemsLength: number;
  selectedPhotosData: any;
  addCheckoutItem: (item) => void;
  addMultipleCheckoutItems: (items) => void;
};
