import React from 'react';
import ProgressBar from './ProgressBar';

const imgWithClick = { cursor: 'pointer' };

const Photo = ({
  index,
  onClick,
  photo: { uuid, width, height, id, isLoading, loadingpercentage },
  photo,
  margin,
  direction,
  top,
  left,
  isDragEntered,
  isSelectionMode,
  addSelectedPhoto,
  selectedPhotos,
  deleteSelectedPhoto,
}: any) => {
  const imgStyle: any = { margin: isSelectionMode ? margin - 3 : margin };
  const isCurrentSelected = selectedPhotos.includes(uuid);
  if (direction === 'column') {
    imgStyle.position = 'absolute';
    imgStyle.left = left;
    imgStyle.top = top;
    imgStyle.zIndex = isDragEntered ? '100' : '102';
  }

  const handleClick = async (event) => {
    if (!isSelectionMode) {
      onClick(event, { photo, index });
    } else {
      isCurrentSelected ? deleteSelectedPhoto(uuid) : addSelectedPhoto(uuid);
    }
  };

  if (isLoading) {
    return (
      <div className="photo-loader" style={onClick ? { ...imgStyle, ...imgWithClick, width: width, height: height } : imgStyle}>
        <ProgressBar progressPercentage={loadingpercentage} />
      </div>
    );
  } else {
    return (
      <img
        style={onClick ? { ...imgStyle, ...imgWithClick } : imgStyle}
        {...photo}
        className={`${isSelectionMode ? 'selection-mode' : ''} ${isCurrentSelected ? 'selection-mode--active' : ''}`}
        onClick={onClick ? handleClick : null}
        alt="img"
      />
    );
  }
};

export default Photo;
