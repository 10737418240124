import * as React from 'react';
import Gallery from 'react-photo-gallery';
import { isEmpty } from 'lodash';
import DropzoneArea from './DropzoneArea';
import Photo from './Photo';

const PhotoPrints = ({ photos, onImageClick, getPhotoPrint, isCustomizationPanelVisible, innerWidth, isSelectionMode, addSelectedPhoto, deleteSelectedPhoto, selectedPhotos }: PhotoPrintsP) => {
  const [isDragEntered, setDragEnter] = React.useState(false);
  const [galleryHeight, setGalleryHeight] = React.useState(undefined);

  return (
    <div className={`photo-prints ${isDragEntered ? 'photo-prints--unscrollable' : ''}`} draggable onDragOver={(event) => !isDragEntered && setDragEnter(!isDragEntered)}>
      <div ref={(el) => setGalleryHeight(el)} className={`photo-prints__gallery-wrapper ${isDragEntered ? 'photo-prints__gallery-wrapper--blurred' : ''}`}>
        {!isEmpty(photos) && (
          <Gallery
            photos={photos}
            direction={'column'}
            margin={15}
            columns={innerWidth > 1280 ? 4 : undefined}
            onClick={onImageClick}
            renderImage={(props) => (
              <Photo
                {...props}
                isDragEntered={isDragEntered}
                isSelectionMode={isSelectionMode}
                addSelectedPhoto={addSelectedPhoto}
                deleteSelectedPhoto={deleteSelectedPhoto}
                selectedPhotos={selectedPhotos}
              />
            )}
          />
        )}
      </div>
      {!isCustomizationPanelVisible && (
        <DropzoneArea getPhotoPrint={getPhotoPrint} setDragEnter={setDragEnter} isDragEntered={isDragEntered} innerHeight={galleryHeight && galleryHeight.clientHeight} />
      )}
    </div>
  );
};

export default PhotoPrints;

type PhotoPrintsP = {
  photos: Array<{ src: string; width: number; height: number }>;
  onImageClick: any;
  isCustomizationPanelVisible: boolean;
  getPhotoPrint: (file) => void;
  innerWidth: number;
  isSelectionMode: boolean;
  addSelectedPhoto: (uuid) => void;
  deleteSelectedPhoto: (uuid) => void;
  selectedPhotos: any;
};
