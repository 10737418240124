import { Prints } from './EnumTypes/ActionEnumTypes';
import * as PrintModule from 'Modules/PrintModule';
import { actions as ViewManagementActions } from 'Modules/ViewManagementModule';
import { v4 as uuidv4 } from 'uuid';

function getPhotoPrintsSuccess(photo) {
  return {
    type: Prints.GET_PHOTOS,
    payload: {
      photo,
    },
  };
}

function changeLoadingStatusSuccess(id) {
  return {
    type: Prints.CHANGE_LOADING_STATUS,
    payload: {
      id,
    },
  };
}

function getPhotoPrints(photo) {
  return async (dispatch) => {
    photo.forEach(async (singlePhoto) => {
      const getWidthAndHeight = new Promise((resolve, reject) => {
        const reader = new FileReader();
        if (photo) {
          reader.readAsDataURL(singlePhoto);
          reader.onload = () => {
            const img: any = new Image();
            img.src = reader.result;
            img.onload = () => {
              resolve({ width: img.width, height: img.height });
            };
          };
        }
      });
      // const dimension = { width: 0, height: 0 };
      singlePhoto.uuid = uuidv4();
      getWidthAndHeight.then((value: any) => {
        singlePhoto.width = value.width;
        singlePhoto.height = value.height;
        dispatch(getPhotoPrintsSuccess(singlePhoto));
      });
      const dataForm = new FormData();
      dataForm.append('image', singlePhoto);

      const {
        data: { uuid },
      } = await PrintModule.connector.addPhoto(singlePhoto.uuid, dataForm);
      dispatch(setFileUuid(singlePhoto.uuid, uuid));
    });
  };
}

function setFileUuid(uuid, file_uuid) {
  return {
    type: Prints.SET_FILE_UUID,
    payload: {
      uuid,
      file_uuid,
    },
  };
}

function setProgressPercentage(uuid, progress) {
  return {
    type: Prints.SET_PROGRESS_PERCENTAGE,
    payload: {
      uuid,
      progress,
    },
  };
}

function deleteActivePhotoSuccess() {
  return {
    type: Prints.DELETE_ACTIVE_PHOTO,
  };
}

const setActivePhotoId = (id) => ({ type: Prints.SET_ACTIVE_PHOTO_ID, payload: { id } });

function getAttributeValuesSuccess(attributes) {
  return {
    type: Prints.GET_ATTRIBUTE_VALUES,
    payload: {
      attributes,
    },
  };
}

function getAttributeValues() {
  return async (dispatch) => {
    try {
      const response = await PrintModule.connector.getAttributeValues();
      dispatch(getAttributeValuesSuccess(response.data));
    } catch (err) {
      console.log(err);
    }
  };
}

function getProductsSuccess(products) {
  return {
    type: Prints.GET_PRODUCTS,
    payload: {
      products,
    },
  };
}

function getProducts() {
  return async (dispatch) => {
    try {
      const response = await PrintModule.connector.getProducts();
      dispatch(getProductsSuccess(response.data));
    } catch (err) {
      console.log(err);
    }
  };
}

function saveCroppedImage(image, dataForm) {
  return async (dispatch, getState) => {
    image.uuid = uuidv4();
    const activePhotoIndex = PrintModule.selectors.getActivePhotoDetailsIndex(getState());
    dispatch(saveCroppedImageSuccess({ ...image, activePhotoIndex }));

    const {
      data: { uuid },
    } = await PrintModule.connector.addPhoto(image.uuid, dataForm);
    dispatch(setFileUuid(image.uuid, uuid));
  };
}

function saveCroppedImageSuccess(image) {
  return {
    type: Prints.SAVE_CROPPED_IMAGE,
    payload: {
      image,
    },
  };
}

function addSelectedPhoto(uuid, areAllToSelect = false) {
  return {
    type: Prints.ADD_SELECTED_PHOTO,
    payload: {
      uuid,
      areAllToSelect,
    },
  };
}

function deleteSelectedPhoto(uuid) {
  return {
    type: Prints.UNCHECK_SELECTED_PHOTO,
    payload: {
      uuid,
    },
  };
}
function clearSelectedPhotos() {
  return {
    type: Prints.UNCHECK_SELECTED_PHOTOS,
  };
}

function deleteSelectedPhotos() {
  return async (dispatch) => {
    dispatch({
      type: Prints.DELETE_SELECTED_PHOTOS,
    });
    dispatch(ViewManagementActions.manageSelectionMode());
  };
}

export {
  getPhotoPrints,
  setActivePhotoId,
  changeLoadingStatusSuccess,
  deleteActivePhotoSuccess,
  getAttributeValues,
  getProducts,
  setProgressPercentage,
  saveCroppedImage,
  addSelectedPhoto,
  deleteSelectedPhoto,
  clearSelectedPhotos,
  deleteSelectedPhotos,
};

// const photos = [
//   {
//     src: Img13,
//     width: 289,
//     height: 359,
//     id: 1,
//   },

//   {
//     src: Img1,
//     width: 289,
//     height: 289,
//     id: 2,
//   },
//   {
//     src: Img14,
//     width: 289,
//     height: 400,
//     id: 3,
//   },
//   {
//     src: Img3,
//     width: 289,
//     height: 289,
//     id: 4,
//   },
//   {
//     src: Img4,
//     width: 289,
//     height: 257,
//     id: 5,
//   },
//   {
//     src: Img6,
//     width: 289,
//     height: 245,
//     id: 6,
//   },
//   {
//     src: Img7,
//     width: 289,
//     height: 186,
//     id: 7,
//   },
//   {
//     src: Img8,
//     width: 289,
//     height: 289,
//     id: 8,
//   },
//   {
//     src: Img9,
//     width: 289,
//     height: 289,
//     id: 9,
//   },
//   {
//     src: Img10,
//     width: 289,
//     height: 180,
//     id: 10,
//   },
//   {
//     src: Img11,
//     width: 289,
//     height: 257,
//     id: 11,
//   },
//   {
//     src: Img12,
//     width: 289,
//     height: 360,
//     id: 12,
//   },

//   {
//     src: Img7,
//     width: 289,
//     height: 186,
//     id: 13,
//   },
//   {
//     src: Img8,
//     width: 289,
//     height: 289,
//     id: 14,
//   },
//   {
//     src: Img13,
//     width: 289,
//     height: 359,
//     id: 15,
//   },
//   {
//     src: Img14,
//     width: 289,
//     height: 400,
//     id: 16,
//   },
// ];
