import { connector } from '../../../Modules/OrderModule';
import { Order } from '../EnumTypes/ActionEnumTypes';
// import { getCheckoutItems, getCheckoutPrices, getVoucher } from 'Store/Selectors/checkout';

const init = () => ({
  type: Order.GET_PAYMENT_STATUS_INIT,
});

const success = (paymentStatus: any) => ({
  type: Order.GET_PAYMENT_STATUS_SUCCESS,
  payload: {
    paymentStatus,
  },
});

const failure = () => ({
  type: Order.GET_PAYMENT_STATUS_FAILURE,
});

const getPaymentStatus = (uuid) => async (dispatch) => {
  dispatch(init());
  try {
    const { data } = await connector.getPaymentStatus(uuid);
    dispatch(success(data));
  } catch (err) {
    await dispatch(failure());
    console.log(err);
  }
};

export default getPaymentStatus;
