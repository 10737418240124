import { connector } from '../../../Modules/CheckoutModule';
import { Checkout } from '../EnumTypes/ActionEnumTypes';
import calculateCheckoutPrice from './calculateCheckoutPrice';
// import * as ViewManagementModule from 'Modules/ViewManagementModule';

const init = () => ({
  type: Checkout.GET_VOUCHER_INIT,
});

const success = (voucher: any) => ({
  type: Checkout.GET_VOUCHER_SUCCESS,
  payload: {
    voucher,
  },
});

const failure = () => ({
  type: Checkout.GET_VOUCHER_FAILURE,
});

const getVoucher = (voucher) => async (dispatch) => {
  dispatch(init());
  setTimeout(async () => {
    try {
      // dispatch(ViewManagementModule.Actions.showLoader());

      const { data } = await connector.getVoucher(voucher);
      dispatch(success(data));
      dispatch(calculateCheckoutPrice());
      // dispatch(ViewManagementModule.Actions.hideLoader());
    } catch (err) {
      // dispatch(ViewManagementModule.Actions.hideLoader());
      await dispatch(failure());
      console.log(err);
    }
  }, 1500);
};

export default getVoucher;
